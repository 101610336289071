import React, { useRef, useCallback } from 'react'

import { Typography, Space, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { InvoiceResponse } from 'common/server/invoice'

import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'
import { OrderCreateResponse } from 'contractor/server/orders'

import { useInvoice } from '../context'
import { CreateQuickPO, CreateQuickPOProps } from '../CreateQuickPO'

export type OrderSearchProps = {
  onSelect: (item: { id: string; order_number: string }) => void
  onClickToCreateQuickPO?: () => void
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
} & Pick<CreateQuickPOProps, 'initialValues' | 'onCreated'>

type FooterProps = {
  onClick: () => void
  setIsOpen?: (isOpen: boolean) => void
  style?: React.CSSProperties
}

const Footer = ({ onClick, setIsOpen, style }: FooterProps) => (
  <Space style={{ padding: 16, ...style }}>
    <Typography.Text>Not seeing your order?</Typography.Text>
    <Typography.Link
      onClick={() => {
        setIsOpen?.(false)
        onClick()
      }}
    >
      Quick create a PO
    </Typography.Link>
  </Space>
)

export const OrderSearch = observer<OrderSearchProps>(
  ({ onSelect, initialValues, onCreated, onClickToCreateQuickPO, saveInvoice }) => {
    const { userStore } = useStores()
    const { lockInvoice } = useInvoice()

    const drawerRef = useRef<DrawerRef>()

    const handleAfterCreateQuickPO = useCallback(
      async (order: OrderCreateResponse) => {
        try {
          onCreated(order)
          await saveInvoice(false, [order?.id])
          drawerRef.current?.close()
        } catch (error) {
          if (error?.response?.data?.error) {
            message.error(error.response.data.error)
          } else {
            message.error('Unable to save the invoice')
          }
        }
      },
      [onCreated],
    )

    const handleClickToCreateQuickPO = () => {
      if (onClickToCreateQuickPO) {
        onClickToCreateQuickPO?.()
        setTimeout(drawerRef.current?.show, 500)
      } else {
        drawerRef.current?.show()
      }
    }

    return (
      <>
        <FlexBoxY width="100%" alignItems="flex-start" justifyContent="center">
          <Typography.Text style={{ paddingBottom: 10 }}>Select the order associated with this Invoice</Typography.Text>
          <OrdersAutocomplete
            onSelect={onSelect}
            footerComponent={userStore.canCreateQuickPO ? <Footer onClick={handleClickToCreateQuickPO} /> : null}
            disabled={!lockInvoice.canEditInvoice}
          />

          {userStore.canCreateQuickPO ? <Footer style={{ padding: 0 }} onClick={handleClickToCreateQuickPO} /> : null}
        </FlexBoxY>

        {userStore.canCreateQuickPO && (
          <CreateQuickPO
            ref={drawerRef}
            onCancel={() => drawerRef.current?.close()}
            onCreated={handleAfterCreateQuickPO}
            initialValues={initialValues}
          />
        )}
      </>
    )
  },
)
