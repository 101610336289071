import _, { flatten } from 'lodash'
import { BindAll } from 'lodash-decorators'
import moment from 'moment'

import { action, computed, observable } from 'mobx'

import { subscribeCompanyChannel, TYPE_CONTRACTOR } from 'common/data-access/company_websocket'
import { OrderChannelMessage, subscribeOrderChannel } from 'common/data-access/order_websocket'
import { noticeError } from 'common/helpers/new_relic'
import {
  calcExtCost,
  canCreateNewCompanyMaterial,
  formatOrderMaterialUnitCost,
  makeCostCode,
  makeOrderMaterial,
  resetCompanyMaterialId,
} from 'common/helpers/order'
import { emptyOrderDelivery, OrderDelivery } from 'common/server/deliveries'
import { OrderHistoryResponse } from 'common/server/order_history'
import { OrderHit, OrderMaterial } from 'common/server/orders'
import { OrderSubStates, QuoteExtractionStates, WebSocketMessage } from 'common/server/server_types'
import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'
import ListBaseStore from 'common/stores/ListBaseStore'

import { emptyOrderMaterial } from 'contractor/server/order_materials'
import { getOrderPackage } from 'contractor/server/order_package'
import { create_order_saving, OrderSavingRequest } from 'contractor/server/order_saving'
import {
  OrderSessionOwner,
  release_session,
  ReleaseOrderSessionBody,
  unlock_session,
  UnlockOrderSessionBody,
  UnlockSessionResponse,
  update_session,
  UpdateOrderSessionBody,
  UpdateOrderSessionResponse,
} from 'contractor/server/order_sessions'
import {
  approveOrder,
  confirmOrder,
  ConsolidatedOrders,
  create,
  create_place_leveling_quotes,
  create_quick_po,
  CreateMergeOrderRequest,
  CreateOrderRequest,
  CreatePlaceLevelingQuotesRequest,
  CreateQuickPORequest,
  export_orders,
  facets,
  getOrderSettings,
  getOrdersLastUpdated,
  history,
  index,
  internal_comment,
  manual_export,
  mergeOrder,
  OrderCreateResponse,
  OrderStateChanges,
  request_update,
  search_key,
  show,
  ShowOrderResponse,
  undiscardOrder,
  update,
} from 'contractor/server/orders'
import { QuoteAdditionalCharge } from 'contractor/server/quote'

const makeDelivery = (delivery: OrderDelivery) => ({
  ...delivery,
  address_id: delivery.address?.id,
  address_to_id: delivery.address_to?.id,
})

const checkCompanyMaterialIsPresent = (orderMaterial: OrderMaterial) => !!orderMaterial.company_material.description

class OrderListStore extends ListBaseStore<ConsolidatedOrders.Order> {
  index = index
  getFacets = facets
  export = export_orders
}

@BindAll()
export default class OrderStore extends AlgoliaBaseStore<OrderHit> {
  listStore: OrderListStore
  getSearchKey = search_key
  // This is when we have an order detail
  @observable selectedOrder: Nullable<ShowOrderResponse> = null
  @observable orderDuplicating: Nullable<ShowOrderResponse> = null
  // This is when we just selected an action from table view
  @observable selectedOrderAction: Nullable<string> = null
  @observable orderSessionOwner: Nullable<OrderSessionOwner> = null
  // Doesn't cover scenario when you make a change then change back
  @observable selectedOrderDirty = false
  @observable isSplitting = false
  @observable orderMaterials: { [deliveryId: string]: OrderMaterial[] } = {} // Used by both new and existing orders
  @observable ordersLastUpdated: OrderHit[] = []
  @observable selectedRibbonFilter: string = null
  @observable totalCost = 0
  newDeliveries = observable.array<OrderDelivery>([emptyOrderDelivery()])
  orderHistory = observable.array<OrderHistoryResponse>([])
  // TODO: might want to rename as "new" tends to imply new quote whereas this captures comments on existing orders
  @observable newComment = ''
  @observable newInternalComment = {
    text: '',
    companyUserIds: [],
  }
  // Used by Order Detail to show Order Changes modal
  orderChangedModal = null
  // Used by Order Table to show that PO PDF Download is available
  downloadPOPdfNotification = null
  downloadRFQPdfNotification = null
  @observable subscriptions = []
  @observable webSocketSubscriptions = new Map()
  // ******************************* Order Supplemental Info *********************************
  orderTags = observable.array<string>([])

  constructor() {
    super()

    this.listStore = new OrderListStore()
  }

  get orders() {
    return this.hits
  }

  // EXISTING ORDERS
  @computed get anySelectedOrderMaterials(): boolean {
    return Boolean(Object.keys(this.orderMaterials).length)
  }

  @computed
  get anyRequestedMaterial(): boolean {
    return this.getPlainOrderMaterials()?.some((orderMaterial) => !!orderMaterial?.company_material?.requested_by_id)
  }

  @computed get anyDelivered(): boolean {
    return _.filter(this.selectedOrder.deliveries, (d) => !!d.actual_delivered_at).length > 0
  }

  @computed get totalMaterialsCount(): number {
    const orderMaterials = this.getPlainOrderMaterials()
    return _.filter(orderMaterials, (om) => om.company_material?.description).length
  }

  @computed get orderMaterialSummary(): OrderMaterial[] {
    // HACK: Grouping by description even though you could have the same description and different product ids
    // Imagine that will be rare in an order though so for now it should do
    const orderMaterials = this.getPlainOrderMaterials()
    const quantized = orderMaterials.map((om) => ({
      ...om,
      quantity: om.quantity ? parseInt(om.quantity as unknown as string) : null,
    }))
    const grouped = _.groupBy(quantized, 'company_material.description')
    const data = _.values(grouped).map((group) => {
      const sum = _.sumBy(group, 'quantity')
      return { ...group[0], quantity: sum }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return _.filter(data, (om) => om.company_material.description) as any
  }

  @action
  updateInternalComment(params: { text: string; companyUserIds: string[] }) {
    this.newInternalComment = params
  }

  @action
  async sendInternalComment(params: { text: string; companyUserIds: string[] }) {
    const { data } = await internal_comment({
      id: this.selectedOrder?.id,
      comment: params.text,
      company_user_ids: params.companyUserIds,
    })

    // The users were complaining about unsaved changes disappearing after sending an internal message.
    // Now we only update the internal messages when the user sends a new message
    if (this.selectedOrder) {
      this.selectedOrder.internal_comments = data?.internal_comments
    }
  }

  @action
  startToSplitOrder() {
    this.isSplitting = true
  }

  @action
  endToSplitOrder() {
    this.isSplitting = false
  }

  @action
  setIsSorted(isSorted: boolean) {
    this.isSorted = isSorted
  }

  @action
  updateOrderMaterials(orderMaterials: OrderMaterial[]) {
    this.selectedOrderDirty = true
    this.orderMaterials = _.groupBy(orderMaterials, 'delivery_id')
  }

  @action
  updateFirstDeliveryAdditionalCharges(payload: QuoteAdditionalCharge) {
    this.selectedOrderDirty = true
    this.selectedOrder.deliveries[0] = {
      ...this.selectedOrder.deliveries[0],
      tax_value: payload.tax,
      shipping_value: payload.shipping,
      discount_value: payload.discount,
    }
  }

  getOrderMaterialsByDeliveryId(deliveryId: string) {
    return this.orderMaterials[deliveryId] || []
  }

  @action
  async handleWebSocketMessage(message: WebSocketMessage, user_id: string) {
    if (message.entity_name === 'Order' && message.entity_id === this.selectedOrder?.id) {
      if (message.type === 'refresh') {
        if (message.data['user_id'] === user_id) {
          // If the same user made the change, we can just refresh the order
          await this.selectOrder(this.selectedOrder.id)
        } else {
          // If is not the same user, we need to show the modal
          this.orderChangedModal?.()
        }
      }
    } else if (message.entity_name === 'Quote' && message.entity_id === this.selectedOrder?.id) {
      if (message.type === 'refresh') {
        await this.selectOrder(this.selectedOrder.id)
      } else if (message.type === 'extracting') {
        if (this.selectedOrderDirty) {
          this.selectedOrder.sub_state = OrderSubStates.QUOTED_RECEIVED
          this.selectedOrder.quote_extraction = {
            ...this.selectedOrder?.quote_extraction,
            quote_state: QuoteExtractionStates.EXTRACTING,
          }
        } else {
          await this.selectOrder(this.selectedOrder.id)
        }
      } else if (message.type === 'correlated') {
        this.orderChangedModal?.()
      }
    } else if (message.entity_name === 'User' && message.entity_id === user_id) {
      if (message.type === 'purchase_order_pdf_available') {
        this.downloadPOPdfNotification?.(message.data['order_number'], message.data['file_url'])
      } else if (message.type === 'request_for_quote_pdf_available') {
        this.downloadRFQPdfNotification?.(message.data['order_number'], message.data['file_url'])
      }
    }
  }

  @action
  handleWebSocketOrderSessionMessage(currentUserId: string) {
    return (message: WebSocketMessage<OrderChannelMessage>) => {
      if (message.data?.order_released) {
        this.orderSessionOwner = null
      }
      if (message.data?.order_will_expire) {
        if (this.orderSessionOwner?.id === currentUserId) {
          this.orderSessionOwner = {
            ...this.orderSessionOwner,
            expired: true,
          }
        }
      }
      if (message.data?.order_blocked && message.data.user?.id !== currentUserId) {
        this.orderSessionOwner = message.data.user
      }
      if (message.data?.order_blocked_manually && message.data.user?.id !== currentUserId) {
        this.orderSessionOwner = {
          ...message.data.user,
          manually_blocked: true,
        }
      }
    }
  }

  orderMaterialsLengthByDeliveryId(deliveryId: string) {
    return this.orderMaterials[deliveryId]?.filter((material) => !!material?.company_material?.description)?.length || 0
  }

  isOverBudget(commitmentId: string) {
    if (!commitmentId) return null

    const materials = this.getOrderMaterialsByDeliveryId(this.newDeliveries[0]?.id)

    const materialQuantityCache = {}

    for (const orderMaterial of materials) {
      if (orderMaterial.remaining_quantity) {
        materialQuantityCache[orderMaterial?.company_material?.id] =
          (materialQuantityCache[orderMaterial?.company_material?.id] || 0) + orderMaterial.quantity

        if (
          Number(materialQuantityCache[orderMaterial?.company_material?.id]) > Number(orderMaterial.remaining_quantity)
        ) {
          return orderMaterial?.company_material?.id
        }
      }
    }

    return null
  }

  orderSubscribe(id, userId) {
    if (this.subscriptions.includes(id)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(id)
    return subscribeCompanyChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, id, userId)
  }

  orderSessionSubscribe(id: string, currentUserId: string) {
    const subscription = subscribeOrderChannel(
      this.handleWebSocketOrderSessionMessage(currentUserId),
      TYPE_CONTRACTOR,
      id,
    )
    this.webSocketSubscriptions.set(id, subscription)
    return subscription
  }

  orderSessionUnsubscribe(id: string) {
    const subscription = this.webSocketSubscriptions.get(id)
    if (subscription) {
      subscription.unsubscribe()
      this.webSocketSubscriptions.delete(id)
    }
  }

  userSubscribe(userId) {
    if (!userId) return
    if (this.subscriptions.includes(userId)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(userId)
    return subscribeCompanyChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, userId, userId)
  }

  @action
  updateOrderMaterialsByDeliveryId(deliveryId: string, newOrderMaterials = []) {
    this.orderMaterials[deliveryId] = newOrderMaterials
  }

  @action
  updateAllOrderMaterials(newOrderMaterials = {}) {
    this.orderMaterials = newOrderMaterials
  }

  // Commenting this unused method (perhaps can be removed)
  // async downloadAsPDF(id: string | null) {
  //   const response = await pdf(id)
  //   const type = response.headers['content-type']
  //   return new Blob([response.data], { type: type })
  // }

  getPlainOrderMaterials() {
    const orderMaterialsMatrix = Object.keys(this.orderMaterials).map((key) => {
      return this.orderMaterials[key]
    })

    return _.flatten(orderMaterialsMatrix)
  }

  validateRequiredFields = (orderMaterials = [], requiredFields) => {
    const errors = []
    const empty = [null, undefined, '']

    for (const field of requiredFields) {
      if (
        orderMaterials.some((material) => {
          if (field.key === 'unit') {
            return (
              !material.unit &&
              empty.includes(material?.company_material.unit_name) &&
              empty.includes(material?.company_material.unit_id)
            )
          }
          if (field.key === 'cost_code_id') {
            return !material.cost_code
          }
          return empty.includes(material[field.key]) && empty.includes(material?.company_material[field.key])
        })
      ) {
        errors.push(field.label)
      }
    }
    return errors
  }

  @action
  setOrderDirty(isDirty = true) {
    this.selectedOrderDirty = isDirty
  }

  // NEW ORDERS
  filteredOrderedMaterials(): OrderMaterial[] {
    const orderMaterials = this.getPlainOrderMaterials()
    return _.filter(orderMaterials, (om) => !['', undefined, null].includes(om.company_material?.description))
  }

  @action
  async getOrderHistory(id: string) {
    const { data } = await history(id)

    const parsedHistory = data?.history.map((history) => ({
      ...history,
      title: history.title.replace('{{title_date}}', moment(new Date(history.title_date)).format('MMM D, h:mm A')),
    }))

    this.orderHistory.replace(parsedHistory)
  }

  @action
  async manual_export(orders: { id: string }[]) {
    try {
      const { data } = await manual_export(orders)

      if (this.selectedOrder) {
        const currentOrder = data.orders.find((order) => order.id === this.selectedOrder?.id)
        this.selectedOrder.manual_exported_at = currentOrder?.manual_exported_at
      }
    } catch (error) {
      noticeError(error, { entry: 'manual-export-orders' })
    }
  }

  async createOrder(
    {
      order_package_id,
      order_package_name,
      order_number,
      quote_number,
      vendors,
      state_changes,
      project_id,
      order_materials = [],
      deliveries = [],
      purchaser_files_signed_ids,
      purchaser_files_delete_ids,
      watcher_ids = [],
      tags,
      requested_by_company_user_id,
      terms_and_condition_id,
      commitment_id,
      splitted_from_id,
      splitted_to_id,
    }: WithOptional<CreateOrderRequest, 'order_materials' | 'deliveries'>,
    canCreateNewMaterial: boolean,
  ): Promise<OrderCreateResponse[]> {
    const newDeliveries = deliveries.length ? deliveries.map(makeDelivery) : this.newDeliveries.map(makeDelivery)
    const newOrderMaterials = order_materials.length
      ? order_materials.filter(checkCompanyMaterialIsPresent)
      : this.filteredOrderedMaterials()

    const { data } = await create({
      order_package_id,
      state_changes,
      order_package_name,
      order_number,
      quote_number,
      project_id,
      vendors,
      purchaser_files_signed_ids,
      purchaser_files_delete_ids,
      watcher_ids: [...watcher_ids, ...this.newInternalComment?.companyUserIds],
      tags,
      requested_by_company_user_id: requested_by_company_user_id,
      order_materials: newOrderMaterials
        .filter((orderMaterial) => canCreateNewCompanyMaterial(orderMaterial, canCreateNewMaterial))
        .map(resetCompanyMaterialId)
        .map(makeOrderMaterial)
        .map(makeCostCode)
        .map(formatOrderMaterialUnitCost),
      deliveries: newDeliveries,
      internal_comment: this.newInternalComment?.text,
      terms_and_condition_id,
      commitment_id,
      splitted_from_id,
      splitted_to_id,
    })

    return data?.orders
  }

  @action
  async selectOrder(id: Nullable<string>) {
    this.setOrderDirty(false)
    this.newComment = ''
    this.newInternalComment = {
      text: '',
      companyUserIds: [],
    }

    if (!id) {
      this.selectedOrder = null
      this.orderMaterials = {}
    } else {
      this.selectedOrder = (await show(id)).data
      this.orderMaterials = _.groupBy(this.selectedOrder.order_materials, 'delivery_id')
    }
  }

  @action
  async updateOrder(
    {
      state_changes,
      purchaser_files_signed_ids,
      purchaser_files_delete_ids,
      quote_signed_id,
      silent_update,
    }: {
      state_changes: OrderStateChanges
      purchaser_files_signed_ids: string[]
      purchaser_files_delete_ids: string[]
      quote_signed_id: string
      silent_update?: boolean
    },
    canCreateNewMaterial: boolean,
  ): Promise<void> {
    const {
      id,
      order_package_name,
      order_number,
      quote_number,
      deliveries,
      watcher_ids,
      tags,
      vendor_contact_ids,
      project_id,
      terms_and_condition_id,
    } = this.selectedOrder
    const payload = {
      id,
      project_id,
      order_package_name,
      order_number,
      quote_number,
      state_changes,
      watcher_ids,
      order_materials: this.filteredOrderedMaterials()
        .filter((orderMaterial) => canCreateNewCompanyMaterial(orderMaterial, canCreateNewMaterial))
        .map(resetCompanyMaterialId)
        .map(makeOrderMaterial)
        .map(makeCostCode),
      deliveries: deliveries.map((d) => ({ ...d, address_id: d.address?.id })),
      purchaser_files_signed_ids,
      purchaser_files_delete_ids,
      quote_signed_id,
      tags,
      silent_update,
      comment: this.newComment,
      vendor_contact_ids,
      terms_and_condition_id,
    }
    this.selectedOrder = (await update(payload)).data
  }

  @action
  async confirmOrder() {
    const payload = { id: this.selectedOrder.id }
    this.selectedOrder = (await confirmOrder(payload)).data
  }

  @action
  async approveOrder() {
    const payload = { id: this.selectedOrder.id }
    this.selectedOrder = (await approveOrder(payload)).data
  }

  async mergeOrder(payload: CreateMergeOrderRequest) {
    this.selectedOrder = (await mergeOrder(payload)).data
  }

  async createOrderSaving(payload: OrderSavingRequest) {
    try {
      await create_order_saving(payload)
    } catch (error) {
      noticeError(error, { entry: 'create-order-saving' })
    }
  }

  splitOrder() {
    const deliveryMapping = {}

    const newDeliveries = this.newDeliveries.map((delivery) => {
      const newDelivery = emptyOrderDelivery()
      newDelivery.address = delivery.address
      newDelivery.requested_delivered_at = delivery.requested_delivered_at

      deliveryMapping[delivery.id] = newDelivery.id
      return newDelivery
    })

    const orderMaterials = this.getPlainOrderMaterials()
    const orderMaterialsSelected = orderMaterials.filter((om) => om.select)

    _.forEach(orderMaterialsSelected, (om) => {
      om['delivery_id'] = deliveryMapping[om['delivery_id']]
      om['id'] = null
    })

    // Remove the selected materials in the split of the current order
    const newOrderMaterials = {}
    Object.keys(this.orderMaterials).forEach((deliveryId) => {
      newOrderMaterials[deliveryId] = this.orderMaterials[deliveryId].filter((orderMaterial) => !orderMaterial.select)
    })
    this.orderMaterials = newOrderMaterials

    return {
      deliveries: newDeliveries,
      order_materials: orderMaterialsSelected,
    }
  }

  @action
  setDuplicateOrder() {
    this.orderDuplicating = this.selectedOrder
  }

  @action
  cleanDuplicateOrder() {
    this.orderDuplicating = null
  }

  @action
  duplicateOrder() {
    if (!this.orderDuplicating) {
      return
    }

    const deliveryMapping = {}
    /* Our current order materials are what we want to duplicate so do the following
      1) Find out how many deliveries we have
      2) Create that amount of new deliveries and a mapping of old to new
      3) Replace all the delivery ids in the old with the new ones
    */
    const deliveries = this.orderDuplicating?.deliveries?.map((delivery) => {
      const newDelivery = emptyOrderDelivery()
      newDelivery.address = delivery.address
      newDelivery.requested_delivered_at = delivery.requested_delivered_at

      newDelivery.is_pick_up = delivery.is_pick_up

      newDelivery.discount_value = delivery.discount_value
      newDelivery.shipping_value = delivery.shipping_value
      newDelivery.other_value = delivery.other_value
      newDelivery.tax_value = delivery.tax_value

      deliveryMapping[delivery.id] = newDelivery.id

      return newDelivery
    })

    this.newDeliveries.replace(deliveries)

    const newOrderMaterials = this.orderDuplicating.order_materials.map((orderMaterial) => {
      return {
        ...orderMaterial,
        has_open_issue: false,
        delivery_id: deliveryMapping[orderMaterial['delivery_id']],
        id: null,
      }
    })

    this.orderMaterials = _.groupBy(newOrderMaterials, 'delivery_id')
  }

  @action
  duplicateDelivery(deliveryIndex: string, deliveries: OrderDelivery[]) {
    const index = parseInt(deliveryIndex, 10)
    const delivery = deliveries[index]

    if (!delivery) {
      return
    }

    const newDelivery = {
      ...emptyOrderDelivery(),
      address: delivery.address,
      is_pick_up: delivery.is_pick_up,
      discount_value: delivery.discount_value,
      shipping_value: delivery.shipping_value,
      other_value: delivery.other_value,
      tax_value: delivery.tax_value,
    }

    deliveries.push(newDelivery)

    const newOrderMaterials = this.orderMaterials[delivery.id].map((orderMaterial) => ({
      ...orderMaterial,
      delivery_id: newDelivery.id,
      id: null,
    }))

    this.orderMaterials[newDelivery.id] = newOrderMaterials
  }

  @action
  async loadDraft(order_package_id: string) {
    const orderPackage = (await getOrderPackage(order_package_id))?.data

    this.selectedOrder = orderPackage.orders[0]
    this.newDeliveries.replace(this.selectedOrder?.deliveries)
    this.orderMaterials = _.groupBy(this.selectedOrder?.order_materials, 'delivery_id')

    return orderPackage
  }

  createQuickPO(payload: CreateQuickPORequest) {
    return create_quick_po(payload).then(({ data }) => data?.orders)
  }

  @action
  updateSelectedOrder<Value = string | number | string[]>(path: string, value: Value): void {
    _.set(this.selectedOrder, path, value)
    this.setOrderDirty()
  }

  @action
  updateNewComment(newComment: string) {
    this.newComment = newComment
    this.setOrderDirty()
  }

  @action
  async undiscardOrder(restoreAsOrder: boolean) {
    const payload = { id: this?.selectedOrder?.id, restore_as_order: restoreAsOrder }
    this.selectedOrder = (await undiscardOrder(payload)).data
  }

  @action
  async sendNewComment(payload: { id: string; comment: string }) {
    this.selectedOrder.comments = (await request_update(payload))?.data.comments
  }

  placeLevelingQuotes(orders: CreatePlaceLevelingQuotesRequest[]) {
    return create_place_leveling_quotes(orders)
  }

  deliveryMaterials(deliveryId): OrderMaterial[] {
    return this.getOrderMaterialsByDeliveryId(deliveryId)
  }

  materialsTotalCost(orderMaterials: OrderMaterial[], precision = 3): number {
    return _.sumBy(orderMaterials, (orderMaterial) => {
      // When the user is editing the order the unit it's inside the order material, otherwise is in the company material.
      const unit = orderMaterial['unit']?.['original'] || orderMaterial?.company_material?.unit

      return calcExtCost({
        unitCost: Number(orderMaterial?.unit_cost),
        quantity: orderMaterial?.quantity,
        multiplier: unit?.multiplier,
        qtyIncrement: unit?.qty_increment,
        precision,
      })
    })
  }

  calcTotalCost({ deliveries, precision = 3 }: { deliveries: OrderDelivery[]; precision?: number }) {
    const orderMaterials = Object.entries(this.orderMaterials)
      .filter(([key, _]) => deliveries.some((delivery) => delivery.id === key))
      .map(([_, value]) => value)

    const materials = flatten(Object.values(orderMaterials))
    const subTotal = this.materialsTotalCost(materials, precision)

    const deliveriesValues = (deliveries || []).reduce(
      (acc, curr) => {
        acc.tax_value += Number(curr.tax_value || 0)
        acc.shipping_value += Number(curr.shipping_value || 0)
        acc.other_value += Number(curr.other_value || 0)
        acc.discount_value += Number(curr.discount_value || 0)
        return acc
      },
      { tax_value: 0, shipping_value: 0, other_value: 0, discount_value: 0 },
    )

    const taxValue = Number(deliveriesValues?.tax_value || 0)
    const shippingValue = Number(deliveriesValues?.shipping_value || 0)
    const otherValue = Number(deliveriesValues?.other_value || 0)

    const discountValue = Number(deliveriesValues?.discount_value || 0)

    const extraValue = taxValue + shippingValue + otherValue

    this.totalCost = subTotal + extraValue - discountValue
  }

  deliveryTotalCost({
    deliveryId,
    deliveries = [],
    materials = [],
    precision = 3,
  }: {
    deliveryId: string
    deliveries?: OrderDelivery[]
    materials?: OrderMaterial[]
    precision?: number
  }): number {
    this.calcTotalCost({ deliveries, precision })

    const currentMaterials = materials.length
      ? materials.filter((material) => material?.delivery_id === deliveryId)
      : this.deliveryMaterials(deliveryId)

    const subTotal = this.materialsTotalCost(currentMaterials, precision)

    const delivery = deliveries.length
      ? deliveries.find((delivery) => delivery.id === deliveryId)
      : this.selectedOrder?.deliveries.find((delivery) => delivery.id === deliveryId)

    const taxValue = Number(delivery?.tax_value || 0)
    const shippingValue = Number(delivery?.shipping_value || 0)
    const otherValue = Number(delivery?.other_value || 0)

    const discountValue = Number(delivery?.discount_value || 0)

    const extraValue = taxValue + shippingValue + otherValue

    return subTotal + extraValue - discountValue
  }

  @action
  clearOrder(size = 5): void {
    this.selectedOrder = null
    this.newDeliveries.replace([emptyOrderDelivery()])
    this.resetOrderMaterials(
      this.newDeliveries.map((d) => d.id),
      size,
    )
  }

  @action
  resetOrderMaterials(delivery_ids: string[], size = 5): void {
    this.orderMaterials = {}
    _.forEach(delivery_ids, (id) => this.addEmptyOrderMaterials(id, size))
  }

  @action
  addEmptyOrderMaterials(delivery_id: string, n: number) {
    const newMaterials = Array(n)
      .fill(undefined)
      .map(() => ({ ...emptyOrderMaterial, delivery_id })) as OrderMaterial[]

    if (this.orderMaterials[delivery_id]) {
      this.orderMaterials[delivery_id] = [...this.orderMaterials[delivery_id], ...newMaterials]
    } else {
      this.orderMaterials[delivery_id] = newMaterials
    }
  }

  @action
  async getOrderSettings(): Promise<void> {
    const response = (await getOrderSettings()).data
    this.orderTags.replace(response['order_tags'])
  }

  @action
  async updateOrderSession(
    payload: UpdateOrderSessionBody,
    user: OrderSessionOwner,
  ): Promise<UpdateOrderSessionResponse> {
    try {
      await update_session(payload)
      this.orderSessionOwner = user
      return { ownerUser: user }
    } catch (error) {
      if (error?.response?.data.error === 'sessions_cannot_be_updated_by_impersonating_user') {
        this.orderSessionOwner = user
        return { ownerUser: user }
      }
      const sessionInUseError = error.response?.data.errors.find(
        (error) => error.name === 'sessions_in_use_by_another_user',
      )
      if (sessionInUseError) {
        this.orderSessionOwner = sessionInUseError.data[0].user
        return { ownerUser: sessionInUseError.data[0].user }
      }
      this.orderSessionOwner = null
      return null
    }
  }

  @action
  async releaseOrderSession(payload: ReleaseOrderSessionBody): Promise<void> {
    try {
      await release_session(payload)
      this.orderSessionOwner = null
    } catch (error) {
      const sessionBelongsAnotherUserError = error.response?.data.errors.find(
        (error) => error.name === 'sessions_belongs_to_another_user',
      )
      if (sessionBelongsAnotherUserError) {
        this.orderSessionOwner = sessionBelongsAnotherUserError.data[0].user
        return
      }
      this.orderSessionOwner = null
    }
  }

  @action
  async unlockOrderSession(payload: UnlockOrderSessionBody, user: OrderSessionOwner): Promise<UnlockSessionResponse> {
    await unlock_session(payload)
    this.orderSessionOwner = user
    return { ownerUser: user }
  }

  @action
  async getOrdersLastUpdated(projectId?: string, state?: string, allowedProjectIds?: string[]): Promise<void> {
    state = state === 'All' ? null : state
    this.ordersLastUpdated = (await getOrdersLastUpdated(projectId, state, allowedProjectIds)).data
  }

  async getOrderById(id: string) {
    const { data } = await show(id)
    return data
  }
}
