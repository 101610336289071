import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

export type LockInvoiceResponse = {
  canEditInvoice: boolean
  canChangeStatusAndAssignee: boolean
  canPostInvoice: boolean
}

export const useLockInvoice = (_: InvoiceResponse) => {
  const { userStore } = useStores()

  return {
    canEditInvoice: userStore.canEditInvoices,
    canChangeStatusAndAssignee: userStore.canChangeStatusAndAssignee,
    canPostInvoice: userStore.canPostInvoices,
  }
}
