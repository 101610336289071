import _ from 'lodash'
import { BindAll } from 'lodash-decorators'

import { action, computed, observable } from 'mobx'

interface SearchStateType {
  sort?: string
  sort_direction?: 'asc' | 'desc'
  filters: {}
  search: string
  page: number
  per_page: number
  enable_session_info?: boolean
}

@BindAll()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default abstract class ListBaseStore<Record = any> {
  abstract index
  records = observable.array<Record>([], { deep: true })
  @observable hasMore = false
  @observable stateCounts: { [key: string]: number } = {}
  @observable totalCount = false
  // We don't want users to be able to override these or clear these at any point so we keep them separate
  @observable pageFilters = {}
  @observable isFetching = false
  @observable facets = {}

  // Page filters are those controlled by us and not the user. For example, the "To Review" page of invoices
  private _perPage = 50
  @observable searchState: SearchStateType = {
    sort: '',
    filters: {},
    search: '',
    page: 1,
    per_page: this._perPage,
    enable_session_info: false,
  }

  @computed get stateWithPageFilters(): SearchStateType {
    const state = _.cloneDeep(this.searchState)

    // If it's an array combine the two arrays, otherwise replace any filters with the page filters
    state.filters = _.mergeWith(state.filters, this.pageFilters, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue)
      }
    })

    return state
  }

  @computed get hasFilters(): boolean {
    return Object.keys(this.searchState.filters).length === 0
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFacets(): Promise<any> {
    throw new Error('getFacets method not implemented in subclass.')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export(params: {}): Promise<any> {
    throw new Error('Export method not implemented in subclass.' + params)
  }

  resetState(): void {
    this.searchState = {
      sort: '',
      sort_direction: undefined,
      filters: {},
      search: '',
      page: 1,
      per_page: this._perPage,
      enable_session_info: false,
    }

    this.clearPageFilters()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter(key: string, value: any, removeIfFalse = true, doNotReFetch = false): void {
    if (removeIfFalse && ((Array.isArray(value) && value.length === 0) || ['', undefined, null].includes(value))) {
      _.unset(this.searchState.filters, key)
    } else {
      this.searchState.filters[key] = value
    }

    // Default to refetching records
    if (!doNotReFetch) {
      this.fetchRecords()
    }
  }

  setSearch(searchText: string): Promise<Record[]> {
    this.searchState.search = searchText
    return this.fetchRecords()
  }

  clearFilters(doNotReFetch = false): void {
    this.searchState.filters = {}

    if (!doNotReFetch) {
      this.fetchRecords()
    }
  }

  setEnableSessionInfo(value: boolean): void {
    this.searchState.enable_session_info = value
  }

  setPageFilters(key, value): void {
    this.pageFilters[key] = value
  }

  clearPageFilters(): void {
    this.pageFilters = {}
  }

  // SEARCH FUNCTIONS
  async fetchRecords(): Promise<Record[]> {
    this.isFetching = true
    this.searchState.page = 1

    const response = (await this.index(this.stateWithPageFilters)).data
    this.records.replace(response.records)

    this.stateCounts = response.state_counts
    // this.stateCounts = Object.keys(response.state_counts).length > 0 ? response.state_counts : { All: 0 }

    this.totalCount = response.total_count
    this.hasMore = response.has_more
    this.isFetching = false
    this.searchState.per_page = this._perPage
    return this.records
  }

  async fetchNextPage(): Promise<void> {
    this.isFetching = true
    this.searchState['page'] += 1
    const response = (await this.index(this.stateWithPageFilters)).data
    this.records.push(...response.records)
    this.totalCount = response.total_count
    this.hasMore = response.has_more
    this.isFetching = false
  }

  async fetchAllRecords(): Promise<Record[]> {
    let page = 1
    let hasMore = true
    const allRecords = []

    while (hasMore) {
      // Use the current search state but increasing the amount per page
      const state = { ...this.stateWithPageFilters, page, per_page: 1000 }
      const response = (await this.index(state)).data

      allRecords.push(...response.records)
      hasMore = response.has_more
      page++
    }

    this.records.replace(allRecords)
    return allRecords
  }

  @action
  clearRecords() {
    this.records.replace([])
  }

  async optimisticUpdateRecords(records: Array<Record>): Promise<void> {
    this.records.replace(records)
  }

  // FACETS
  async fetchFacets(): Promise<void> {
    const response = await this.getFacets()
    this.facets = response.data
  }

  async exportRecords(hiddenColumns?: string[], extras = {}): Promise<void> {
    const response = await this.export({
      ...this.stateWithPageFilters.filters,
      hidden_columns: hiddenColumns,
      ...extras,
    })
    return response.data
  }
}
