import React from 'react'

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Alert, Badge, Button, Divider, Modal, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { OrderDeliveryCosts } from 'common/components/@v2/OrderDeliveryCosts'
import { DataSource, OrderMaterials } from 'common/components/@v2/OrderMaterials/order_materials'
import { OrderMaterialTabs } from 'common/components/@v2/OrderMaterialTabs'
import { Box } from 'common/components/boxes'
import { formatEnumValue } from 'common/helpers/formatters'
import { calcOrderTaxSplitBulk, roundValue } from 'common/helpers/order'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { useTheme } from 'common/hooks/use-theme'
import { emptyOrderDelivery } from 'common/server/deliveries'
import { OrderStates } from 'common/server/server_types'

import { DeliveryInfo } from 'contractor/components/OrderDeliveries'
import { getDeliveryPickUpTitle } from 'contractor/helpers/get-delivery-pick-up-title'
import { useStores } from 'contractor/hooks/use-stores'

import { Invoices } from '../Invoices'
import { MaterialAndDeliveriesTable } from './material_and_deliveries_table'

type MaterialsAndDeliveriesProps = {
  isPoLocked?: boolean
  onDirty?: () => void
  isSpreadsheetMode?: boolean
}

export const MaterialsAndDeliveries = observer(
  ({ onDirty, isPoLocked, isSpreadsheetMode }: MaterialsAndDeliveriesProps) => {
    const { orderStore, userStore, companySettingStore, costCodeStore, unitsStore } = useStores()
    const {
      project_id,
      deliveries = [],
      company_vendor,
      state,
      commitment_id,
      project,
      tags,
      invoices,
    } = orderStore.selectedOrder
    const isMobileScreen = useMediaQuery('md')

    const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const requiredOrderFields = companySettingStore.otherSettings?.required_order_fields
    const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
    const orderType = state === OrderStates.QUOTED ? 'RFQ' : 'Order'
    const showDeleteItem =
      orderStore?.selectedOrder?.requested_by_company_user_id == userStore?.currentUser?.company_user_id ||
      !userStore?.cannotSendAndUpdateOrders

    const editDisabled = userStore.cannotSendAndUpdateOrders || isPoLocked
    const editCostCodeDisabled = (userStore.cannotEditCostCode && userStore.cannotSendAndUpdateOrders) || isPoLocked
    const hasRenderInvoice = userStore.canUseInvoices && !isMobileScreen

    const { costCodePhaseListStore } = costCodeStore

    const handleAddDelivery = () => {
      const newDelivery = emptyOrderDelivery()
      deliveries.push(newDelivery)
      orderStore.addEmptyOrderMaterials(newDelivery.id, 5)
      orderStore.setOrderDirty()
    }

    const handleRemoveDelivery = (index: number) => {
      Modal.confirm({
        title: 'Are you sure you want to remove this delivery?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deliveries.splice(index, 1)
          orderStore.setOrderDirty()
          orderStore.calcTotalCost({
            deliveries,
            precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
          })
        },
      })
    }

    const handleAdd = () => {
      const button = document.getElementsByClassName('aa-DetachedSearchButton')[0] as HTMLElement
      const input = document.getElementsByClassName('aa-Input')[0] as HTMLElement
      button?.click()
      input?.focus()
    }

    let deliveryCount = 0
    let pickUpCount = 0

    const deliveryItems = deliveries?.map((delivery, index) => {
      delivery.is_pick_up ? pickUpCount++ : deliveryCount++

      const deliveryName = getDeliveryPickUpTitle({
        isPickUp: delivery.is_pick_up,
        pickUpCount,
        deliveryCount,
      })

      const dataSource = orderStore
        .getOrderMaterialsByDeliveryId(delivery.id)
        .filter((data) => !!data.company_material?.description)

      return {
        title: deliveryName,
        label: (
          <OrderMaterialTabs.TabPaneTitle
            title={deliveryName}
            isPickUp={delivery.is_pick_up}
            delivery={delivery}
            isRemovable={deliveries.length > 1}
            handleRemove={() => handleRemoveDelivery(index)}
          />
        ),
        key: `${index}`,
        closable: deliveries.length > 1,
        children: (
          <>
            {delivery?.has_open_issue && (
              <Alert
                closable
                message={`${formatEnumValue(delivery?.delivery_issue_type || '')}: ${delivery?.delivery_issues || ''}`}
                type="error"
                style={{ marginBottom: 12 }}
              />
            )}
            <DeliveryInfo
              project_ids={[project_id]}
              disabled={isPoLocked || !userStore.canEditDeliveryInformation}
              delivery={delivery}
              showDetails
              companyVendorId={company_vendor?.id}
            />

            {isSpreadsheetMode ? (
              <MaterialAndDeliveriesTable
                delivery_id={delivery.id}
                costCodeDisabled={editCostCodeDisabled}
                isPoLocked={isPoLocked}
                deliveryName={deliveryName}
              />
            ) : (
              <>
                <Box display={{ _: 'flex', xs: 'none' }} width="100%" flexDirection="column">
                  <Box width="100%" display="flex" justifyContent="space-between">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Materials
                    </Typography.Title>
                    <Button icon={<PlusOutlined />} onClick={handleAdd} size="small" />
                  </Box>

                  <Divider style={{ margin: '12px 0' }} />
                </Box>

                <OrderMaterials
                  showDefaultVendor={company_attributes.includes('preferred_vendor_prices')}
                  projectId={project_id}
                  commitmentId={commitment_id}
                  companyVendorId={company_vendor?.id}
                  orderType={orderType}
                  dataSource={dataSource as unknown as Array<Omit<DataSource, 'internal_id'>>}
                  onChange={(newOrderMaterials) => {
                    if (
                      newOrderMaterials.some((material) => !!material?.tax_value) &&
                      project?.tax_line_items?.enabled
                    ) {
                      const totalTaxSumFromOrderMaterials = newOrderMaterials.reduce(
                        (acc, material) => acc + (Number(material?.tax_value) || 0),
                        0,
                      )

                      delivery.tax_value = roundValue(totalTaxSumFromOrderMaterials)
                    }

                    orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
                    onDirty?.()
                  }}
                  isSelecting={orderStore.isSplitting}
                  hideCostCode={!company_attributes?.includes('cost_code_id')}
                  hideTax={!project?.tax_line_items?.enabled}
                  deliveryId={delivery.id}
                  disabled={orderStore.isSplitting || editDisabled}
                  showMaterialPriceDetails={userStore.canViewHistoricalPricing}
                  unitCostInput={{ hideDetails: !userStore.canUseHistoricalCost }}
                  roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
                  costCodeInput={{
                    costCodes: toJS(costCodeStore.costCodeListStore.records),
                    projectId: project_id,
                    costCodeSettings,
                  }}
                  vendorResponseInput={{ edit: false }}
                  unitInput={{ units: toJS(unitsStore.units), disabled: !userStore.canCreateNewMaterial }}
                  units={toJS(unitsStore.units)}
                  phaseCodeInput={{
                    phaseCodes: toJS(costCodePhaseListStore.records),
                    costCodeSettings,
                    projectId: project_id,
                  }}
                  requiredFields={{
                    order: requiredOrderFields,
                    quote: requiredQuoteFields,
                  }}
                  costCodeSettings={costCodeSettings}
                  canEditMaterialDatabase={userStore.canEditMaterialDatabase}
                  canViewHistoricalPricing={userStore.canViewHistoricalPricing}
                  canCreateNewMaterial={userStore.canCreateNewMaterial && !commitment_id}
                  showDeleteItem={showDeleteItem}
                  userId={userStore.currentUser?.id}
                  orderState={orderStore?.selectedOrder?.state}
                  costCodeDisabled={editCostCodeDisabled}
                />
                <OrderDeliveryCosts
                  index={index}
                  disabled
                  costsDisabled={editDisabled}
                  isPoLocked={isPoLocked}
                  deliveryChargesUnits={delivery.delivery_charges_units}
                  onChangeChargesUnit={(value) => (delivery.delivery_charges_units = value)}
                  hideAddTotal={userStore.cannotSendAndUpdateOrders || editDisabled}
                  deliveryTotalCost={orderStore.deliveryTotalCost({
                    deliveryId: delivery.id,
                    deliveries,
                    precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
                  })}
                  orderMaterialsTotalCost={orderStore.materialsTotalCost(
                    orderStore.getOrderMaterialsByDeliveryId(delivery.id),
                  )}
                  onChange={(value) => {
                    delivery.discount_value = value.discountValue
                    delivery.shipping_value = value.shippingValue
                    delivery.other_value = value.otherValue
                    delivery.tax_value = value.taxValue

                    if (project?.tax_line_items?.enabled) {
                      const newOrderMaterials = calcOrderTaxSplitBulk({
                        orderMaterials: dataSource,
                        taxAmount: value.taxValue,
                        taxLineItemsEnabled: true,
                        precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
                      })
                      orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
                    }

                    orderStore.setOrderDirty()
                  }}
                  roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
                  value={{
                    discountValue: delivery?.discount_value,
                    shippingValue: delivery?.shipping_value,
                    otherValue: delivery?.other_value,
                    taxValue: delivery?.tax_value,
                  }}
                  deliveryName={deliveryName}
                />
              </>
            )}
          </>
        ),
      }
    })

    const theme = useTheme()
    const getBadgeStyle = () => {
      if (invoices.length) {
        return {
          color: theme.colors['geekblue-9'],
          background: theme.colors['geekblue-2'],
          borderRadius: theme.radii.lg,
        }
      }
      return {
        color: theme.colors['gray-13'],
        background: theme.colors['gray-6'],
        borderRadius: theme.radii.lg,
      }
    }

    const invoiceItem = hasRenderInvoice && {
      title: 'Invoices',
      label: (
        <OrderMaterialTabs.TabPaneTitle
          title={
            <Box display="flex" alignItems="center" style={{ gap: 8 }}>
              <Typography.Text>Invoices</Typography.Text>
              <Badge count={String(invoices.length).padStart(2, '0')} showZero style={getBadgeStyle()} />
            </Box>
          }
          isInvoice
        />
      ),
      key: 'invoices',
      className: 'invoices-tab',
      children: <Invoices />,
    }

    return (
      <OrderMaterialTabs
        onDuplicate={(deliveryIndex) => orderStore.duplicateDelivery(deliveryIndex, deliveries)}
        onAdd={handleAddDelivery}
        disabled={editDisabled}
        deliveriesCount={deliveries.length}
        items={[...deliveryItems, invoiceItem].filter(Boolean)}
        tagsProps={{
          value: tags,
          disabled: editDisabled,
          onChange: (tags) => orderStore.updateSelectedOrder('tags', tags),
          tags: orderStore.orderTags,
          hidden: isMobileScreen,
        }}
      />
    )
  },
)
