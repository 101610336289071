import { useRouteMatch } from 'react-router'

import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { ShowOrderResponse } from 'contractor/server/orders'
import { Quote } from 'contractor/server/quote'
import { ShowUserResponse } from 'contractor/server/user'

export type UseOrderDataType = {
  currentUser?: ShowUserResponse
  precision?: number
  selectedOrder?: ShowOrderResponse
  totalCost?: number
  isLoading: boolean
  lockOrderRule?: string
  selectedOrderDirty?: boolean
  quote?: Quote
}

export const useOrderData = () => {
  const { params } = useRouteMatch()

  const {
    orderStore,
    userStore,
    companySettingStore,
    unitsStore,
    costCodeStore,
    companyMaterialStore,
    addressStore,
    integrationStore,
    projectStore,
    quoteStore,
  } = useStores()

  // Until we have company_attributes populated just return null
  const { isLoading } = useQuery(() => {
    return Promise.all([
      orderStore.orderSubscribe(params['id'], userStore.currentUser?.id),
      orderStore.selectOrder(params['id']),
      companyMaterialStore.loadSearchKey(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      unitsStore.maybeUnits(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeStore.fetchAllActivePhaseCodes(),
      companySettingStore.maybeIndexUsers(),
      addressStore.maybeIndexAddresses(),
      orderStore.getOrderSettings(), // For Tags
      projectStore.maybeIndexProjects(),
      companySettingStore.indexOtherSettings(),
    ])
  })

  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useQuery(() => {
    return quoteStore.getQuote(orderStore.selectedOrder?.id, orderStore.selectedOrder?.quote_extraction?.id)
  }, [orderStore.selectedOrder?.quote_extraction?.id, orderStore?.selectedOrder?.id])

  const data = {
    currentUser: userStore.currentUser,
    isLoading,
    lockOrderRule: companySettingStore?.otherSettings?.lock_order_settings?.lock_order_rule,
    precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
    selectedOrder: orderStore.selectedOrder,
    selectedOrderDirty: orderStore.selectedOrderDirty,
    quote: quoteStore.quote,
    totalCost: orderStore.totalCost,
  }

  return data
}
