import React from 'react'

import { Typography, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'

import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'

import { CompanyVendorSelector } from './company_vendor_selector'

export const InvoiceHeader = observer(() => {
  const { invoiceStore } = useStores()

  const invoice = invoiceStore.invoice
  const lockInvoice = useLockInvoice(invoice)

  const handleChangeName = (name: string) => {
    invoiceStore.updateSelectedInvoice('name', name)
  }

  return (
    <Box display="flex" alignItems="baseline" style={{ gap: 16 }}>
      <Tooltip title="Name" placement="bottom">
        <Typography.Title
          aria-label="invoice-name-title"
          style={{ margin: 0 }}
          level={5}
          editable={
            lockInvoice.canEditInvoice
              ? {
                  onChange: handleChangeName,
                }
              : undefined
          }
        >
          {invoiceStore.invoice?.name || 'Invoice'}
        </Typography.Title>
      </Tooltip>

      <Visibility.Hidden breakpoint="lg">
        <CompanyVendorSelector disabled={!lockInvoice.canEditInvoice} />
      </Visibility.Hidden>
    </Box>
  )
})
