import React from 'react'

import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { InputProps, Popover, Space, Tooltip, Typography } from 'antd'

import { toJS } from 'mobx'

import { Box } from 'common/components/boxes'
import {
  EditableCellInputCurrency,
  EditableCellInputNumber,
  EditableCellInputTextArea,
  EditableCellSelectCostCode,
  EditableCellSelectPhaseCode,
  EditableCellSelectUnit,
} from 'common/components/EditableTable'
import { InputInvoiceMaterialPercentage } from 'common/components/InputCurrencyPercentage/InvoiceMaterial/input_invoice_material_percentage'
import { UUID_REGEXP } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceMaterial } from 'common/server/invoice'
import { Unit } from 'common/server/units'

import { useFlag } from 'contractor/hooks/use-flag'
import { usePopulateSameCostCode } from 'contractor/hooks/use-populate-same-cost-code'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { MappedStatus } from './mapped_status'

export const getInvoiceMaterialsColumns = () => {
  const { userStore, costCodeStore, companySettingStore, invoiceStore, unitsStore } = useStores()

  const {
    setSelectedInvoiceDirty,
    calcExtCost,
    currencyFormatter,
    taxAmountField,
    calculatedEligibleTaxSplitTotal,
    taxLineItemsEnabled,
    lockInvoice,
  } = useInvoice()

  useQuery(unitsStore.maybeUnits)
  const theme = useTheme()
  const canUseRetainage = useFlag('retainage_v1')

  const populateSameCostCode = usePopulateSameCostCode<InvoiceMaterial>({
    itemIdentifierKey: 'id',
  })

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const overridesExtPrice = React.useRef<string[]>([])

  const handleApplyCostCodeToAll = (args) => {
    const { index, value, path } = args

    const newMaterials = populateSameCostCode.handleBulkChange({
      triggerIndex: index,
      path,
      value,
      datasource: toJS(invoiceStore.invoice.invoice_materials),
    })

    invoiceStore.updateSelectedInvoice('invoice_materials', newMaterials)
  }

  const handleChangeCallback = (newInvoiceMaterials = []) => {
    invoiceStore.updateSelectedInvoice('invoice_materials', newInvoiceMaterials)
    setSelectedInvoiceDirty(true)
  }

  const getItemByRowIndex = (rowIndex) => {
    const newInvoiceMaterials = [...invoiceStore.invoice.invoice_materials]
    const index = newInvoiceMaterials.findIndex((_, index) => index === rowIndex)
    const item = newInvoiceMaterials[index]

    return { newInvoiceMaterials, item, index }
  }

  const handleExtendedCostWithUnit = ({
    id,
    unit,
    unitCost,
    quantity,
    extendedPrice,
  }: {
    id: string
    unit?: Unit
    unitCost: number
    quantity: number
    extendedPrice: number
  }) => {
    if (overridesExtPrice.current.includes(id)) {
      // If the user has override the field, we don't want to recalculate the extended price
      return extendedPrice
    }
    return calcExtCost({
      unitCost: unitCost,
      quantity: quantity,
      multiplier: unit?.multiplier,
      qtyIncrement: unit?.qty_increment,
    })
  }

  const handleChangeDescription = (description, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      description,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const handleChangeQuantity = (quantity, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    const extendedPrice = handleExtendedCostWithUnit({
      id: item.id,
      unit: item.unit,
      unitCost: item.unit_price,
      quantity,
      extendedPrice: item.extended_price,
    })
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      quantity_shipped: quantity,
      extended_price: extendedPrice,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const handleChangeUom = (uom, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    let unit = null

    // Got this from the SelectUnit component (don't why it's not working without the parsing)
    const units = JSON.parse(JSON.stringify(unitsStore.units))

    // check if uom is an uuid string with regexp
    // if it is an uuid string, find the unit with that id
    if (typeof uom === 'string' && UUID_REGEXP.test(uom)) {
      // If the selector returns a UOM as uuid
      unit = units.find((u) => u.id === uom)
      uom = unit?.name
    } else if (typeof uom === 'object') {
      // If the selector returns a UOM as object (label/value)
      unit = units.find((u) => u.id === uom.value)
      uom = unit?.name
    }
    const extendedPrice = handleExtendedCostWithUnit({
      id: item.id,
      unit: unit,
      unitCost: item.unit_price,
      quantity: item.quantity_shipped,
      extendedPrice: item.extended_price,
    })

    newInvoiceMaterials.splice(index, 1, {
      ...item,
      uom,
      unit,
      extended_price: extendedPrice,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const handleChangeUnitPrice = (unitPrice, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    const extendedPrice = handleExtendedCostWithUnit({
      id: item.id,
      unit: item.unit,
      unitCost: unitPrice,
      quantity: item.quantity_shipped,
      extendedPrice: item.extended_price,
    })
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      unit_price: unitPrice,
      extended_price: extendedPrice,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const handleChangeExtendedPrice = (extendedPrice, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    if (extendedPrice !== item.extended_price) {
      overridesExtPrice.current.push(item.id)
    }
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      extended_price: extendedPrice,
    })
    handleChangeCallback(newInvoiceMaterials)
  }

  const handleChangeCostCode = (costCodeValue, rowIndex, shouldPropagateValue) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)

    const newCostCodeValue = costCodeValue?.costCode || costCodeValue
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      cost_code: newCostCodeValue,
    })

    const path = 'cost_code'

    if (shouldPropagateValue) {
      const newInvoiceMaterialsWithCostCodes = populateSameCostCode.handleBulkChange({
        triggerIndex: rowIndex,
        path,
        value: newCostCodeValue,
        datasource: toJS(newInvoiceMaterials),
      })

      handleChangeCallback(newInvoiceMaterialsWithCostCodes)
    } else {
      if (populateSameCostCode.hasItemBeenChangedByAppliedToAll(path, item.id)) {
        populateSameCostCode.removedByAppliedToAll(path, item.id)
      }
      handleChangeCallback(newInvoiceMaterials)
    }
  }

  const handleChangePhaseCode = (phaseCodeId, rowIndex, shouldPropagateValue) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)
    newInvoiceMaterials.splice(index, 1, {
      ...item,
      cost_code_phase_id: phaseCodeId,
    })

    const path = 'cost_code_phase_id'

    if (shouldPropagateValue) {
      const newInvoiceMaterialsWithCostCodes = populateSameCostCode.handleBulkChange({
        triggerIndex: rowIndex,
        path,
        value: phaseCodeId,
        datasource: toJS(newInvoiceMaterials),
      })

      handleChangeCallback(newInvoiceMaterialsWithCostCodes)
    } else {
      if (populateSameCostCode.hasItemBeenChangedByAppliedToAll(path, item.id)) {
        populateSameCostCode.removedByAppliedToAll(path, item.id)
      }
      handleChangeCallback(newInvoiceMaterials)
    }
  }

  const handleChangeRetainage = (value, type, percentage, rowIndex) => {
    const { newInvoiceMaterials, item, index } = getItemByRowIndex(rowIndex)

    if (value || percentage) {
      newInvoiceMaterials.splice(index, 1, {
        ...item,
        retainage_amount: value,
        retainage_type: type,
        retainage_percentage: percentage,
      })
    } else if (!value && !percentage) {
      newInvoiceMaterials.splice(index, 1, {
        ...item,
        retainage_amount: null,
        retainage_type: null,
        retainage_percentage: null,
      })
    }
    handleChangeCallback(newInvoiceMaterials)
  }

  const firstInvoiceOrder = [...invoiceStore.selectedOrders]?.[0]?.order
  const projectId = firstInvoiceOrder?.project_id || invoiceStore.invoice?.project?.id

  const raw = [
    { dataIndex: 'action', render: (_, row) => <MappedStatus invoiceMaterial={row} />, width: 36 },
    {
      title: 'Invoice Description',
      dataIndex: 'description',
      render: (description) => {
        return (
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
            {description}
          </Typography.Paragraph>
        )
      },
      editable: () => lockInvoice.canEditInvoice,
      renderEditable: (_, rowIndex) => (
        <EditableCellInputTextArea
          onSave={(value) => handleChangeDescription(value, rowIndex)}
          inputName="description"
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity_shipped',
      align: 'right',
      width: 75,
      editable: () => lockInvoice.canEditInvoice,
      renderEditable: (record, rowIndex) => {
        const getStatus = (): InputProps['status'] => {
          if (!record?.order_material) return null
          if (record?.quantity_shipped?.toString() !== record?.order_material?.quantity?.toString()) {
            return 'warning'
          }
        }

        return (
          <EditableCellInputNumber
            onSave={(value) => handleChangeQuantity(value, rowIndex)}
            inputName="quantity_shipped"
            status={getStatus()}
          />
        )
      },
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      width: 100,
      render: (_, record) => record?.unit?.name || record?.uom,
      editable: () => lockInvoice.canEditInvoice,
      renderEditable: (record, rowIndex) => {
        const getStatus = (): InputProps['status'] => {
          if (!record?.order_material) return null

          const companyMaterial = record?.order_material?.company_material
          const unitName = record?.unit?.name || record?.uom

          if (!!companyMaterial?.unit && unitName !== companyMaterial?.unit?.name) {
            return 'warning'
          }

          if (unitName !== companyMaterial?.unit_name) {
            return 'warning'
          }
        }

        return (
          <EditableCellSelectUnit
            onSave={(value) => handleChangeUom(value, rowIndex)}
            inputName="uom"
            units={unitsStore.units}
            status={getStatus()}
          />
        )
      },
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_price',
      width: 100,
      align: 'right',
      render: (value = 0) => {
        return <Typography.Text style={{ whiteSpace: 'nowrap' }}>{currencyFormatter(value)}</Typography.Text>
      },
      editable: () => lockInvoice.canEditInvoice,
      renderEditable: (_, rowIndex) => (
        <EditableCellInputCurrency onSave={(value) => handleChangeUnitPrice(value, rowIndex)} inputName="unit_price" />
      ),
    },
    ...(taxLineItemsEnabled
      ? [
          {
            title: 'Tax',
            dataIndex: 'tax',
            width: 100,
            align: 'right',
            editable: () => false,
            render: (_, record) => {
              if (!record?.accepts_tax_split) {
                return <Typography.Text type="secondary">not applied</Typography.Text>
              }

              const extCost = calcExtCost({
                unitCost: record?.unit_price,
                quantity: record?.quantity_shipped,
                multiplier: record?.unit?.multiplier,
                qtyIncrement: record?.unit?.qty_increment,
              })

              const taxField = Number(taxAmountField) || 0
              const taxSplit = Number(record.tax_split) || 0

              return (
                <Space>
                  <Typography.Text>{currencyFormatter(taxSplit)}</Typography.Text>
                  {Number(taxSplit) !== 0 && (
                    <Popover
                      content={
                        <Space direction="vertical">
                          <Typography.Text>Tax Calculation:</Typography.Text>
                          <Typography.Text strong>
                            ${taxField} * (${extCost} / ${calculatedEligibleTaxSplitTotal}) = ${taxSplit}
                          </Typography.Text>
                        </Space>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  )}
                </Space>
              )
            },
          },
          {
            title: 'Ext. Cost + Tax',
            dataIndex: 'ext_cost_with_tax',
            width: 120,
            align: 'right',
            render: (_, row) => {
              const calculedExtCost = calcExtCost({
                unitCost: row?.unit_price,
                quantity: row?.quantity_shipped,
                multiplier: row?.unit?.multiplier,
                qtyIncrement: row?.unit?.qty_increment,
              })

              return <Typography.Text>{currencyFormatter(calculedExtCost + row.tax_split)}</Typography.Text>
            },
          },
        ]
      : []),
    {
      title: 'Ext. Cost',
      dataIndex: 'extended_price',
      width: 100,
      align: 'right',
      render: (value = 0, record) => {
        const calculedExtCost = calcExtCost({
          unitCost: record?.unit_price,
          quantity: record?.quantity_shipped,
          multiplier: record?.unit?.multiplier,
          qtyIncrement: record?.unit?.qty_increment,
        })

        // If the extended price is different from the calculated one, we highlight it
        const hasDifferentExtCost = calculedExtCost !== Number(value)

        return (
          <Box display="inline-flex" alignItems="center" style={{ gap: 4 }}>
            {hasDifferentExtCost && (
              <Popover
                content={
                  <Typography.Text style={{ width: 250, display: 'block' }}>
                    The calculated Ext. Cost (
                    <Typography.Text strong underline>
                      {currencyFormatter(calculedExtCost)}
                    </Typography.Text>
                    ) is different from the current (
                    <Typography.Text strong underline>
                      {currencyFormatter(value)}
                    </Typography.Text>
                    ). Please double-check that everything is correct.
                  </Typography.Text>
                }
                placement="topRight"
                arrowPointAtCenter
              >
                <ExclamationCircleOutlined style={{ color: theme.colors.error, fontSize: 12 }} />
              </Popover>
            )}
            <Typography.Text type={hasDifferentExtCost ? 'danger' : undefined} style={{ whiteSpace: 'nowrap' }}>
              {currencyFormatter(value)}
            </Typography.Text>
          </Box>
        )
      },
      editable: () => lockInvoice.canEditInvoice,
      renderEditable: (record, rowIndex) => {
        const getStatus = (): InputProps['status'] => {
          if (!record?.order_material) return null

          const extPrice =
            Number(record?.order_material?.unit_cost || 0) * Number(record?.order_material?.quantity || 0)
          if (record?.extended_price !== extPrice) {
            return 'warning'
          }
        }

        return (
          <EditableCellInputCurrency
            onSave={(value) => handleChangeExtendedPrice(value, rowIndex)}
            inputName="extended_price"
            status={getStatus()}
          />
        )
      },
    },
    ...(userStore.canUseCostCode && company_attributes.includes('cost_code_id')
      ? [
          {
            title: 'Cost Code',
            dataIndex: 'cost_code',
            width: 200,
            render: (costCode) => {
              if (!costCode) return null

              const canShowPhase =
                costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled && !!costCode
              const canShowClass = costCodeSettings?.class_enabled && !!costCode

              return (
                <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
                  <Tooltip title={costCode?.description}>
                    <Typography.Text>
                      {[
                        canShowPhase ? costCode?.phase_code || 'N/A' : '',
                        costCode?.code,
                        canShowClass ? costCode?.clazz || 'N/A' : '',
                      ]
                        .filter((item) => !!item)
                        .join(' / ')}
                    </Typography.Text>
                  </Tooltip>
                </Box>
              )
            },
            editable: () => lockInvoice.canEditInvoice,
            renderEditable: (record, rowIndex) => {
              return (
                <>
                  <EditableCellSelectCostCode
                    onSave={(value, shouldPropagateValue) =>
                      handleChangeCostCode(value, rowIndex, shouldPropagateValue)
                    }
                    inputName="cost_code"
                    costCodes={costCodeStore.costCodeListStore.records}
                    costCodeSettings={costCodeSettings}
                    projectId={projectId}
                    autoFocus={false}
                    defaultApplyToAllChecked={record.default_cost_code_to_be_applied}
                    onChangeApplyToAll={(args) => {
                      handleApplyCostCodeToAll({
                        ...args,
                        index: rowIndex,
                      })
                    }}
                  />
                </>
              )
            },
          },
        ]
      : []),
    ...(costCodeSettings?.independent_phase_codes_enabled
      ? [
          {
            title: 'Phase Code',
            dataIndex: 'cost_code_phase_id',
            width: 200,
            render: (costCode, record) => {
              if (!costCode) return null

              const costCodePhase = costCodeStore?.costCodePhaseListStore.records?.find(
                (costCodePhase) => costCodePhase.id === record?.cost_code_phase_id,
              )

              return (
                <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
                  <Tooltip title={costCodePhase?.description}>
                    <Typography.Text>{costCodePhase?.code}</Typography.Text>
                  </Tooltip>
                </Box>
              )
            },
            editable: () => lockInvoice.canEditInvoice,
            renderEditable: (record, rowIndex) => {
              return (
                <>
                  <EditableCellSelectPhaseCode
                    onSave={(value, shouldPropagateValue) =>
                      handleChangePhaseCode(value, rowIndex, shouldPropagateValue)
                    }
                    inputName="cost_code_phase_id"
                    phaseCodes={costCodeStore.costCodePhaseListStore.records}
                    autoFocus={false}
                    costCodeSettings={costCodeSettings}
                    projectId={projectId}
                    style={{ marginTop: 4 }}
                    defaultApplyToAllChecked={record.default_cost_code_to_be_applied}
                    onChangeApplyToAll={(args) => {
                      handleApplyCostCodeToAll({
                        ...args,
                        index: rowIndex,
                      })
                    }}
                  />
                </>
              )
            },
          },
        ]
      : []),
    ...(canUseRetainage
      ? [
          {
            title: 'Retainage amount',
            dataIndex: 'retainage',
            width: 190,
            render: (_, record) => {
              if (record?.retainage_type === 'PERCENTAGE') {
                return (
                  <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
                    {record.retainage_percentage}%
                  </Box>
                )
              }

              return (
                <Box style={{ whiteSpace: 'nowrap' }} display="flex" flexDirection="column">
                  {currencyFormatter(record?.retainage_amount)}
                </Box>
              )
            },
            editable: () => lockInvoice.canEditInvoice,
            renderEditable: (record, rowIndex) => {
              return (
                <>
                  <InputInvoiceMaterialPercentage
                    onChange={(value: number, type: string, percentage: number) =>
                      handleChangeRetainage(value, type, percentage, rowIndex)
                    }
                    totalCost={record.extended_price}
                    retainageAmount={record.retainage_amount}
                    retainageType={record.retainage_type}
                    roundingPrecision={3}
                    retainagePercentage={record.retainage_percentage}
                  />
                </>
              )
            },
          },
        ]
      : []),
  ]

  return raw.filter(Boolean).map((column, index) => ({
    ...column,
    tabIndex: index,
  }))
}
