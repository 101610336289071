import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef, DrawerProps } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import { useQuery } from 'common/hooks/use-query'

import { InvoiceHistory as InvoiceHistoryComponent } from 'contractor/components/InvoiceHistory/history'
import { useStores } from 'contractor/hooks/use-stores'

type InvoiceHistoryProps = Partial<DrawerProps>

export const InvoiceHistory = observer<InvoiceHistoryProps, DrawerRef>(
  (props, ref) => {
    const { invoiceStore } = useStores()

    const { isLoading } = useQuery(() => {
      if (invoiceStore.invoice?.id && props.open) {
        invoiceStore.getInvoiceHistory(invoiceStore.invoice?.id)
      }
    }, [invoiceStore.invoice?.id, props.open])

    return (
      <Drawer closable ref={ref} width={480} title={`Invoice #${invoiceStore.invoice?.number} History`} {...props}>
        <Box display="flex" flexDirection="column" width="100%">
          <ErrorBoundary isFull={false}>
            <Box display="flex" flexDirection="column" p={16} width="100%" overflowY="auto" flexGrow={1}>
              <InvoiceHistoryComponent isLoading={isLoading} data={invoiceStore.invoiceHistory} />
            </Box>
          </ErrorBoundary>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
