import React from 'react'

import { Button, message, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { InvoiceMailDetail } from 'common/components/InvoiceMail/invoice_mail_detail'
import { Loading } from 'common/components/Loading'
import { formatDateString } from 'common/helpers/formatters'
import { ShowInvoiceMailResponse, ShowInvoiceUploadResponse } from 'common/server/invoice'
import { InvoiceInboxSubStates, InvoiceSourceType } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { InboxState } from 'contractor/pages/@v2/Invoices/components/inbox_state'
import { consolidatedInvoiceInboxesRequests } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'
import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

import { InvoiceInboxDetailsDrawerErrorAlert } from './alert_failed'
import { InvoiceInboxDetailsDrawerWarnAlert } from './alert_warn'
import { InboxAttachments } from './inbox_attachments'
import { InvoicePdfSelector } from './invoice_pdf_selector'
import { ReportIssue } from './report_issue'

interface Props {
  invoicesInboxSourceId: string
  invoiceInboxType: InvoiceSourceType
  onLoadInvoiceInbox?: (
    sourceId: string,
    sourceType: InvoiceSourceType,
    inboxId?: string,
  ) => Promise<[ConsolidatedInvoiceInboxes.InvoiceInbox, ShowInvoiceUploadResponse | ShowInvoiceMailResponse]>
  onSendIssue?: (invoiceInboxId: string, issueMessage: string, invoiceInboxAttachmentIds: string[]) => Promise<void>
  onSendTip?: (invoiceInboxId: string, text: string) => Promise<void>
  onIgnore?: (invoiceInboxId: string) => Promise<void>
  onClose?: () => void
  ref: React.ForwardedRef<DrawerRef>
  invoiceInboxId?: string
}

function isUpload(
  inboxSource: ShowInvoiceUploadResponse | ShowInvoiceMailResponse,
): inboxSource is ShowInvoiceUploadResponse {
  return 'uploaded_by_company_user' in inboxSource
}

const Component = React.forwardRef((props: Props, ref: React.ForwardedRef<DrawerRef>) => {
  const {
    invoiceInboxId,
    invoiceInboxType,
    invoicesInboxSourceId,
    onLoadInvoiceInbox,
    onSendIssue,
    onSendTip,
    onIgnore,
    onClose,
  } = props

  const pdfSelectorDrawerRef = React.useRef<DrawerRef>()

  const [invoiceInbox, setInvoiceInbox] = React.useState<ConsolidatedInvoiceInboxes.InvoiceInbox>(null)
  const [inboxSource, setInboxSource] = React.useState<ShowInvoiceUploadResponse | ShowInvoiceMailResponse>(null)
  const [invoiceInboxLoading, setInvoiceInboxLoading] = React.useState(false)
  const [openIssueModal, setOpenIssueModal] = React.useState(false)

  React.useEffect(() => {
    async function loadInvoiceInbox() {
      try {
        if (!invoicesInboxSourceId || invoiceInboxLoading) {
          return
        }

        setInvoiceInboxLoading(true)

        const [invoiceInbox, inboxSource] = await onLoadInvoiceInbox(
          invoicesInboxSourceId,
          invoiceInboxType,
          invoiceInboxId,
        )

        setInvoiceInbox(invoiceInbox)
        setInboxSource(inboxSource)
      } catch (error) {
        message.error('Failed to load invoice inbox')
      } finally {
        setInvoiceInboxLoading(false)
      }
    }

    loadInvoiceInbox()
  }, [onLoadInvoiceInbox, invoiceInboxId, invoicesInboxSourceId, invoiceInboxType])

  const handleClose = () => {
    setInvoiceInbox(null)
    setInboxSource(null)
    onClose()
  }

  function renderContent() {
    if (invoiceInboxLoading || !invoiceInbox) {
      return (
        <Box display="flex" justifyContent="center">
          <Loading />
        </Box>
      )
    }

    if (invoiceInboxType === 'InvoiceUpload' && inboxSource && isUpload(inboxSource)) {
      return (
        <>
          <Box alignItems="flex-start" width="100%">
            <Typography.Text strong>Uploaded By</Typography.Text>
            <Typography.Paragraph style={{ margin: 0 }}>
              {inboxSource.uploaded_by_company_user?.user?.name}
            </Typography.Paragraph>
          </Box>
          <Box alignItems="flex-start" width="100%">
            <Typography.Text strong>Uploaded At</Typography.Text>
            <Typography.Paragraph style={{ margin: 0 }}>
              {formatDateString(inboxSource.created_at)}
            </Typography.Paragraph>
          </Box>
        </>
      )
    }

    return (
      <Box>
        <InvoiceMailDetail invoiceMail={inboxSource as ShowInvoiceMailResponse} />
      </Box>
    )
  }

  return (
    <Drawer
      ref={ref}
      onClose={handleClose}
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography.Text>Inbox Detail</Typography.Text>

          {invoiceInbox && (
            <Box display="inline-flex" justifyContent="flex-end">
              {[InvoiceInboxSubStates.FINISHED, InvoiceInboxSubStates.FAILED].includes(invoiceInbox.sub_state) && (
                <>
                  <Button
                    type="default"
                    style={{ minWidth: 120, marginRight: 10 }}
                    size="small"
                    onClick={() => setOpenIssueModal(true)}
                  >
                    Something is wrong?
                  </Button>
                  <ReportIssue
                    invoiceInboxId={invoiceInbox?.id}
                    open={openIssueModal}
                    onSendIssue={(...params) => onSendIssue(...params).then(() => setOpenIssueModal(false))}
                    onClose={() => setOpenIssueModal(false)}
                    pdfFiles={inboxSource?.pdf_files}
                  />
                </>
              )}
              <InboxState
                state={invoiceInbox.state}
                sub_state={invoiceInbox.sub_state}
                count={invoiceInbox.invoice_count}
              />
            </Box>
          )}
        </Box>
      }
    >
      {!invoiceInbox || !inboxSource ? (
        <Loading />
      ) : (
        <Box overflowY="auto" display="flex" flexDirection="column" gridGap={16} flexGrow={1} width="100%" p={24}>
          <InvoiceInboxDetailsDrawerWarnAlert
            onSendIssue={onSendIssue}
            onSendTip={(text) => onSendTip(invoiceInbox.invoice_inbox_id, text)}
            invoiceInbox={invoiceInbox}
            pdfFiles={inboxSource?.pdf_files}
          />

          <InvoiceInboxDetailsDrawerErrorAlert
            onIgnore={() => onIgnore(invoiceInbox.invoice_inbox_id)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onCreateInvoices={() => pdfSelectorDrawerRef.current?.show() as any}
            invoiceInbox={invoiceInbox}
          />

          <InboxAttachments consolidatedInbox={invoiceInbox} />

          {renderContent()}
        </Box>
      )}

      <InvoicePdfSelector
        inbox_id={invoiceInbox?.id}
        files={inboxSource?.pdf_files as ConsolidatedInvoiceInboxes.File[]}
        ref={pdfSelectorDrawerRef}
        onClose={handleClose}
      />
    </Drawer>
  )
})

export const InvoiceInboxDetailsDrawer = observer<Props>(
  (props, ref: React.RefObject<DrawerRef>) => {
    const { invoiceInboxStore } = useStores()

    function refreshInboxTable() {
      invoiceInboxStore.listStore.fetchRecords()
      invoiceInboxStore.listStore.fetchFacets()
    }

    function handleClose() {
      if (props.onClose) return props.onClose()
      ref.current?.close()
    }

    async function handleSendIssue(invoiceInboxId: string, issueMessage: string, invoiceInboxAttachmentIds: string[]) {
      try {
        await invoiceInboxStore.reportIssue(invoiceInboxId, {
          issue_message: issueMessage,
          invoice_inbox_attachment_ids: invoiceInboxAttachmentIds,
        })
        message.success('Issue sent')
        refreshInboxTable()
        handleClose()
      } catch (error) {
        message.error(error?.response?.data?.error || 'Failed to send issue')
        throw error
      }
    }

    async function handleSendTip(id: string, text: string) {
      try {
        await invoiceInboxStore.reportTip(id, { tip_message: text })
        message.success('Tip sent')
        handleClose()
      } catch (error) {
        message.error('Failed to send tip')
      }
    }

    async function handleIgnore(id: string) {
      try {
        await invoiceInboxStore.ignoreFailures(id)
        message.success('Marked as ignored')
        handleClose()
        await invoiceInboxStore.listStore.fetchRecords()
      } catch (error) {
        message.error('Failed to ignore')
      }
    }

    async function handleLoadInvoiceInbox(
      sourceId: string,
      sourceType: InvoiceSourceType,
      inboxId?: string,
    ): Promise<[ConsolidatedInvoiceInboxes.InvoiceInbox, ShowInvoiceMailResponse | ShowInvoiceUploadResponse]> {
      let invoiceSource = null

      if (sourceType === 'InvoiceMail') {
        invoiceSource = await invoiceInboxStore.selectInvoiceMail(sourceId)
      }

      if (sourceType === 'InvoiceUpload') {
        invoiceSource = await invoiceInboxStore.selectInvoiceUpload(sourceId)
      }

      const invoiceInboxId = inboxId || invoiceSource.invoice_inbox_id
      let invoiceInbox = null
      if (invoiceInboxId) {
        const { data } = await consolidatedInvoiceInboxesRequests.show(invoiceInboxId)
        invoiceInbox = data
        invoiceInbox.files = invoiceInbox.files?.map((file) => ({
          ...file,
          extras: {
            ...file?.extras,
            invoices: invoiceSource?.invoices,
          },
        }))
      }

      return [invoiceInbox, invoiceSource]
    }

    return (
      <Component
        onClose={handleClose}
        onLoadInvoiceInbox={handleLoadInvoiceInbox}
        onSendIssue={handleSendIssue}
        onSendTip={handleSendTip}
        onIgnore={handleIgnore}
        {...props}
        ref={ref}
      />
    )
  },
  { forwardRef: true },
)
