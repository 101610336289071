import React, { createContext, useContext, useEffect, useState } from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import moment from 'moment'

import { Form, FormInstance, message, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { getUnit } from 'common/components/OrderMaterialsV2'
import { calcExtCost } from 'common/helpers/order'
import { useNotifyCommitment } from 'common/hooks/use-notify-commitment'
import { useQuery } from 'common/hooks/use-query'
import { CommitmentStatuses } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment, CommitmentMaterial } from 'contractor/server/commitments'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

const CommitmentContext = createContext<CommitmentContextProps>({} as CommitmentContextProps)

export const useCommitment = () => useContext(CommitmentContext)

type CommitmentContextProps = {
  companyVendors: IndexCompanyVendor[]
  commitment: Commitment
  setCompanyVendors: React.Dispatch<React.SetStateAction<IndexCompanyVendor[]>>
  selectedCompanyVendor: IndexCompanyVendor
  setSelectedCompanyVendor: React.Dispatch<React.SetStateAction<IndexCompanyVendor>>
  commitmentNumber: string
  projectId: string
  tags: string[]
  commitmentName: string
  expirationDate: string
  handleUpdateCommitment: () => Promise<void>
  handleDeleteCommitment: () => Promise<void>
  handleCreateSubmittedCommitment: () => Promise<void>
  handleSubmitCommitment: () => Promise<void>
  handleSaveDraftCommitment: () => Promise<void>
  isSubmitting: boolean
  goBack: () => void
  validateCommitment: () => void
  form: FormInstance
  isDraft: boolean
  isDirty: boolean
  setDirty: React.Dispatch<React.SetStateAction<boolean>>
  setShowConfirmSubmitVisible: React.Dispatch<React.SetStateAction<boolean>>
  confirmSubmitVisible: boolean
  updateSelectedCommitment: (path: string, value: string) => void
  calculatedBudgetedTotal: number
  calculatedOrdered: number
  ordersToImport: string[]
  setOrdersToImport: React.Dispatch<React.SetStateAction<string[]>>
}

export const CommitmentProvider = observer(({ children }) => {
  const {
    projectStore,
    companySettingStore,
    unitsStore,
    costCodeStore,
    companyVendorStore,
    vendorStore,
    uploaderStore,
    commitmentStore,
    userStore,
  } = useStores()

  const history = useHistory()
  const { notifyCommitment } = useNotifyCommitment()
  const [form] = Form.useForm()

  const [companyVendors, setCompanyVendors] = useState([])
  const [selectedCompanyVendor, setSelectedCompanyVendor] = useState<IndexCompanyVendor>(null)
  const [isSubmitting, setSubmitting] = React.useState(false)
  const [isDirty, setDirty] = React.useState(false)
  const [ordersToImport, setOrdersToImport] = React.useState<string[]>([])
  const [confirmSubmitVisible, setShowConfirmSubmitVisible] = React.useState(false)
  const isDraft = commitmentStore?.selectedCommitment?.status === CommitmentStatuses.DRAFT
  const commitment = commitmentStore?.selectedCommitment
  const updateSelectedCommitment = commitmentStore?.updateSelectedCommitment
  const commitmentNumber = Form.useWatch('commitmentNumber', form)
  const projectId = Form.useWatch('projectId', form) || commitment?.project_id
  const commitmentName = Form.useWatch('commitmentName', form)
  const expirationDate = Form.useWatch('expirationDate', form)
  const tags = Form.useWatch('tags', form)
  const params = new URLSearchParams(useLocation().search)

  const { id } = useParams()

  const goBack = () => {
    commitmentStore.listStore?.fetchAllRecords()
    history.push('/commitments')
  }

  useEffect(() => {
    const projectIdParam = params.get('project_id')

    if (projectIdParam && !projectId) {
      form.setFieldsValue({ projectId: projectIdParam })
    }
  }, [params])

  const calculatedBudgetedTotal = React.useMemo(() => {
    return commitment?.commitment_materials?.reduce((acc, material) => {
      const unit = material?.unit
      const option = unit ? unit : getUnit(material.company_material)
      const extendedCost = calcExtCost({
        unitCost: material?.unit_price,
        quantity: material?.quantity,
        multiplier: option?.original?.multiplier,
        qtyIncrement: option?.original?.qty_increment,
      })
      return acc + extendedCost
    }, 0)
  }, [commitment?.commitment_materials])

  const calculatedOrdered = React.useMemo(() => {
    return commitment?.commitment_materials?.reduce((acc, material) => {
      const unit = material?.unit
      const option = unit ? unit : getUnit(material.company_material)
      const extendedCost = calcExtCost({
        unitCost: material?.unit_price,
        quantity: material?.quantity_ordered,
        multiplier: option?.original?.multiplier,
        qtyIncrement: option?.original?.qty_increment,
      })
      return acc + extendedCost
    }, 0)
  }, [commitment?.commitment_materials])

  const validateCommitmentMaterial = () => {
    return {
      message: 'Must specify materials or add an attachment',
      invalid: uploaderStore.signedIds('purchaser_files').length === 0 && commitmentStore.totalMaterialsCount === 0,
    }
  }

  const validateCommitmentQuantities = (isSubmitting: boolean) => {
    if (!isSubmitting) return { message: '', invalid: false }

    return {
      message: 'Must specify quantities for all materials',
      invalid: commitmentStore.selectedCommitment.commitment_materials.some((material) => !material.quantity),
    }
  }

  const validateCommitmentProject = () => {
    return {
      message: 'Must select a project.',
      invalid: !projectId,
    }
  }

  const validateCommitmentName = () => {
    return {
      message: 'Must enter a name for the commitment.',
      invalid: !commitmentName,
    }
  }

  const validateCommitmentVendor = () => {
    return {
      message: 'Must specify a vendor.',
      invalid: !selectedCompanyVendor,
    }
  }

  const validateUploads = () => {
    return {
      message: 'Uploads have not completed yet, please try again',
      invalid: !uploaderStore.checkIfAllUploadsCompleted(),
    }
  }

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || `Unable to save the commitment`)
    }
  }

  const loadCommitment = async () => {
    if (id) {
      commitmentStore.selectedCommitment = null
      commitmentStore.loadCommitment(id).then(() => {
        form.setFieldsValue({
          commitmentName: commitmentStore.selectedCommitment?.commitment_name,
          projectId: commitmentStore.selectedCommitment?.project_id,
          commitmentNumber: commitmentStore.selectedCommitment?.commitment_number,
          expirationDate: commitmentStore.selectedCommitment?.expiration_date,
          watchers: commitmentStore.selectedCommitment?.watchers?.map((watcher) => ({
            value: watcher.id,
            label: watcher.full_name,
          })),
        })
        if (commitmentStore.selectedCommitment?.tags) {
          form.setFieldsValue({ tags: commitmentStore.selectedCommitment.tags })
        }
      })
    }
  }

  const validateCommitment = (isSubmitting?: boolean) => {
    const validations = [
      validateCommitmentMaterial(),
      validateUploads(),
      validateCommitmentProject(),
      validateCommitmentVendor(),
      validateCommitmentName(),
      validateCommitmentQuantities(isSubmitting),
    ]
    if (validations.some((validation) => validation.invalid)) {
      const validation = validations.find((validation) => validation.invalid)
      const title = validation?.message
      Modal.error({ title })
      return false
    }
    return true
  }

  const maybeResetCompanyMaterialId = (commitmentMaterial: CommitmentMaterial) => {
    let currentCommitmentMaterial = commitmentMaterial

    if (
      !!commitmentMaterial?.unit &&
      commitmentMaterial?.unit?.value === commitmentMaterial?.unit?.label &&
      commitmentMaterial?.unit?.label !== commitmentMaterial?.company_material?.unit_name
    ) {
      currentCommitmentMaterial = {
        ...currentCommitmentMaterial,
        company_material: {
          ...currentCommitmentMaterial.company_material,
          unit_name: commitmentMaterial?.unit?.label,
          unit_id: null,
          id: null,
        },
      }
    }

    if (
      !!commitmentMaterial?.unit &&
      commitmentMaterial?.unit?.value !== commitmentMaterial?.unit?.label &&
      commitmentMaterial?.unit?.value !== commitmentMaterial?.company_material?.unit_id
    ) {
      currentCommitmentMaterial = {
        ...currentCommitmentMaterial,
        company_material: {
          ...currentCommitmentMaterial.company_material,
          unit_name: null,
          unit_id: currentCommitmentMaterial?.unit?.value,
          id: null,
        },
      }
    }

    return currentCommitmentMaterial
  }

  const createCommitmentPayload = (extraParams = {}) => {
    return {
      commitment_name: commitmentName,
      project_id: projectId,
      commitment_number: commitmentNumber,
      company_vendor_id: selectedCompanyVendor.id,
      expiration_date: expirationDate,
      commitment_materials: commitmentStore.selectedCommitment.commitment_materials.map(maybeResetCompanyMaterialId),
      tags: tags,
      internal_comments: commitmentStore.selectedCommitment.internal_comments,
      watcher_ids: commitmentStore.selectedCommitment.watcher_ids,
      attachments_files_signed_ids: uploaderStore.signedIds('attachments'),
      attachments_files_delete_ids: uploaderStore.deleteAttachmentIds['attachments'],
      orders_to_import: ordersToImport,
      ...extraParams,
    } as Commitment
  }

  const handleUpdateCommitment = async () => {
    if (!validateCommitment()) return

    try {
      setSubmitting(true)
      const payload = createCommitmentPayload({ id: id })
      await commitmentStore.updateCommitment(payload)

      notifyCommitment({
        message: 'Commitment Updated',
        commitmentId: id,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleDeleteCommitment = async () => {
    try {
      commitmentStore.deleteSelectedCommitment()
      notifyCommitment({
        message: 'Commitment Deleted',
        commitmentId: id,
      })
      goBack()
    } catch (error) {
      handleLogError(error)
    }
  }

  const createEmptyCommitment = () => {
    commitmentStore.userSubscribe(userStore?.userId)
    commitmentStore.selectedCommitment = {
      commitment_materials: [],
      commitment_name: '',
      commitment_number: '',
      internal_comments: [],
      tags: [],
      status: CommitmentStatuses.DRAFT,
    } as Commitment
  }

  const handleSaveDraftCommitment = async () => {
    if (!validateCommitment()) return

    try {
      setSubmitting(true)
      const payload = createCommitmentPayload()
      let commitmentId = 'new'
      const commitment = await commitmentStore.createCommitment(payload)
      commitmentId = commitment.id

      notifyCommitment({
        message: 'Commitment saved as draft',
        commitmentId: commitmentId,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCreateSubmittedCommitment = async () => {
    if (!validateCommitment(true)) return

    try {
      setSubmitting(true)
      const payload = createCommitmentPayload({ submitted_at: moment().toISOString() })
      let commitmentId = 'new'
      await commitmentStore.createCommitment(payload).then((commitment) => {
        commitmentId = commitment.id
      })

      notifyCommitment({
        message: 'Commitment submitted.',
        commitmentId: commitmentId,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSubmitCommitment = async () => {
    if (!validateCommitment(true)) return

    try {
      setSubmitting(true)
      const payload = createCommitmentPayload({ submitted_at: moment().toISOString(), id: id })
      await commitmentStore.updateCommitment(payload)

      notifyCommitment({
        message: 'Commitment submitted',
        commitmentId: id,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (id !== 'new') {
      loadCommitment()
      commitmentStore?.commitmentSubscribe(id, userStore?.userId)
    }
  }, [id])

  useEffect(() => {
    createEmptyCommitment()
  }, [id])

  const { isLoading } = useQuery(() => {
    return Promise.all<unknown>([
      projectStore.maybeIndexProjects(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      companySettingStore.indexOtherSettings(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeStore.fetchAllActivePhaseCodes(),
      companyVendorStore.getAllCompanyVendors(true),
      unitsStore.maybeUnits(),
      vendorStore.getAllVendors(),
    ])
  })

  useEffect(() => {
    setCompanyVendors(companyVendorStore?.companyVendors)
  }, [companyVendorStore?.companyVendors])

  useEffect(() => {
    if (commitmentStore?.selectedCommitment?.company_vendor_id) {
      const companyVendor = companyVendors?.find(
        (companyVendor) => companyVendor.id == commitmentStore?.selectedCommitment.company_vendor_id,
      )
      setSelectedCompanyVendor(companyVendor)
    }
  }, [commitmentStore?.selectedCommitment, companyVendors, isLoading])

  if (isLoading) {
    return <Loading />
  }

  return (
    <CommitmentContext.Provider
      value={{
        companyVendors,
        setSelectedCompanyVendor,
        selectedCompanyVendor,
        setCompanyVendors,
        handleUpdateCommitment,
        commitmentNumber,
        projectId,
        commitmentName,
        isSubmitting,
        goBack,
        form,
        isDraft,
        isDirty,
        setDirty,
        setShowConfirmSubmitVisible,
        confirmSubmitVisible,
        commitment,
        updateSelectedCommitment,
        calculatedBudgetedTotal,
        calculatedOrdered,
        handleCreateSubmittedCommitment,
        handleSaveDraftCommitment,
        handleSubmitCommitment,
        validateCommitment,
        expirationDate,
        tags,
        handleDeleteCommitment,
        ordersToImport,
        setOrdersToImport,
      }}
    >
      {children}
    </CommitmentContext.Provider>
  )
})

export const withCommitmentProvider = (Component) => (props) => {
  return (
    <CommitmentProvider>
      <Component {...props} />
    </CommitmentProvider>
  )
}
