import React, { useRef } from 'react'

import { Typography, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxY } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'

import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import { useStores } from 'contractor/hooks/use-stores'

import { CreateQuickPO, CreateQuickPOProps } from '../CreateQuickPO'

export type OrderSearchProps = {
  onSelect: (item: { id: string; order_number: string }) => void
  disabled?: boolean
} & Pick<CreateQuickPOProps, 'initialValues' | 'onCreated'>

type FooterProps = {
  onClick: () => void
  setIsOpen?: (isOpen: boolean) => void
  style?: React.CSSProperties
}

const Footer = ({ onClick, setIsOpen, style }: FooterProps) => (
  <Space style={{ padding: 16, ...style }}>
    <Typography.Text>Not seeing your order?</Typography.Text>
    <Typography.Link
      onClick={() => {
        setIsOpen?.(false)
        onClick()
      }}
    >
      Quick create a PO
    </Typography.Link>
  </Space>
)

export const OrderSearch = observer<OrderSearchProps>(({ onSelect, initialValues, onCreated, disabled }) => {
  const { userStore } = useStores()

  const drawerRef = useRef<DrawerRef>()

  return (
    <>
      <FlexBoxY width="100%" alignItems="flex-start" justifyContent="center">
        <Typography.Text style={{ padding: 10 }}>Select the order associated with this Invoice</Typography.Text>
        <Box width="100%">
          <OrdersAutocomplete
            onSelect={onSelect}
            footerComponent={userStore.canCreateQuickPO ? <Footer onClick={() => drawerRef.current?.show()} /> : null}
            disabled={disabled}
          />

          {userStore.canCreateQuickPO ? (
            <Footer style={{ padding: 0 }} onClick={() => drawerRef.current?.show()} />
          ) : null}
        </Box>
      </FlexBoxY>

      {userStore.canCreateQuickPO && (
        <CreateQuickPO
          ref={drawerRef}
          onCancel={() => drawerRef.current?.close()}
          onCreated={(order) => {
            return onCreated(order).then(() => drawerRef.current?.close())
          }}
          initialValues={initialValues}
        />
      )}
    </>
  )
})
