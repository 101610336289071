import { useHistory, useLocation } from 'react-router'

import _ from 'lodash'

import { message, Modal } from 'antd'

import { useNotifyOrder } from 'common/hooks/use-notify-order'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderStates, OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { useCancelOrderAction, UseCancelOrderActionType } from './useCancelOrderAction'
import { UseOrderStatesType } from './useOrderStates'
import { usePlaceOrderAction, UsePlaceOrderActionType } from './usePlaceOrderAction'
import { useRequestCancellationAction, UseRequestCancellationActionType } from './useRequestCancellationAction'

export type UseOrderInternalActions = {
  getRequiredOrderFields: () => {
    key: string
    label: string
  }[]
  handleMaybeAddWatcher: () => void
  handleValidateProject: () => string[]
  handleLogError: (error, message: string) => void
  handleSaveOrder: (
    state_changes: {
      ordered_at?: string
      cancelled_at?: string
      cancellation_accepted_at?: string
      cancellation_rejected_at?: string
      cancellation_requested_at?: string
      cancellation_requested_reason?: string
    },
    silent_update?: boolean,
  ) => Promise<void>
}

export type UseOrderActionsType = {
  handleGoBack: () => void
  handleRedirectToDraftPage: () => void
  handleSave: (params: {
    stateChanges?: { cancelled_at?: Nullable<string> }
    action?: number
    silentUpdate?: boolean
    isBack?: boolean
  }) => Promise<void>
  handleSendInternalComment: ({ text, companyUserIds }) => Promise<void>
  handleDirtyOrder: () => void
  handleUpdateOrderPackageName: (name?: string) => void
  handleSelectOrder: (orderId: string) => Promise<void>
  handleConfirmOrder: () => Promise<void>
  handleManualExport: (orders: { id: string }[]) => Promise<void>
} & UsePlaceOrderActionType &
  UseCancelOrderActionType &
  UseRequestCancellationActionType

export const useOrderActions = (states: UseOrderStatesType): UseOrderActionsType => {
  const history = useHistory()
  const location = useLocation()
  const { orderStore, userStore, uploaderStore, companySettingStore, deliveryStore } = useStores()

  const { notifyOrder } = useNotifyOrder({ orderId: orderStore.selectedOrder?.id, operation: 'update' })
  const { push } = usePushToOrder()

  const getRequiredOrderFields = () => [
    ...(companySettingStore.otherSettings?.required_order_fields?.quantity
      ? [
          {
            key: 'quantity',
            label: 'Quantity',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.unit
      ? [
          {
            key: 'unit',
            label: 'Unit',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.unit_cost
      ? [
          {
            key: 'unit_cost',
            label: 'Unit Cost',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.cost_code
      ? [
          {
            key: 'cost_code_id',
            label: 'Cost Code',
          },
        ]
      : []),
  ]

  const handleGoBack = () => {
    orderStore.selectOrder(null)

    if (location.key && location.key !== 'default') {
      return history.goBack()
    }

    history.replace('/orders')
  }

  /*
    The user can switch the project and we should verify if no problem between materials
    and/or cost codes when the company has enabled the configuration to relate both with the projects.
  */
  const handleValidateProject = () => {
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const isProjectFilteringEnabled = costCodeSettings?.project_filtering_enabled
    const isProjectSpecificMaterialEnabled =
      companySettingStore.companyMaterialConfiguration?.company_attributes.includes('project_ids')

    if (!isProjectFilteringEnabled && !isProjectSpecificMaterialEnabled) {
      return []
    }

    const errors = []
    const orderMaterials = orderStore.getPlainOrderMaterials()
    const order = orderStore.selectedOrder
    orderMaterials.forEach((orderMaterial) => {
      const companyMaterial = orderMaterial?.company_material
      if (
        isProjectSpecificMaterialEnabled &&
        Array.isArray(companyMaterial?.project_ids) &&
        companyMaterial?.project_ids?.length &&
        !companyMaterial?.project_ids?.includes(order?.project_id)
      ) {
        errors.push(
          `The project ${order?.project?.name} is not allowed to be related to the material: ${orderMaterial?.company_material?.description}.`,
        )
      }

      const costCode = orderMaterial?.cost_code
      if (
        isProjectFilteringEnabled &&
        costCode?.project_ids.length &&
        !costCode?.project_ids?.includes(order?.project_id)
      ) {
        const canShowPhase =
          costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled && !!costCode
        const canShowClass = costCodeSettings?.class_enabled && !!costCode

        const codeLabel = [
          canShowPhase ? costCode?.phase_code || 'N/A' : '',
          costCode?.code,
          canShowClass ? costCode?.clazz || 'N/A' : '',
        ]
          .filter((item) => !!item)
          .join(' / ')
        errors.push(`The project ${order?.project?.name} is not allowed to be related to the cost code: ${codeLabel}.`)
      }
    })

    return errors
  }

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || 'Unable to save updates to the order.')
    }
  }

  // Hack to redirect to the draft page if the user access the order directly
  const handleRedirectToDraftPage = () => {
    const { id, order_package_id, state, sub_state } = orderStore.selectedOrder
    if ([OrderStates.DRAFT, OrderStates.REQUESTED].includes(state)) {
      push({
        orderId: id,
        orderPackageId: order_package_id,
        state: state,
        subState: sub_state,
      })
    }
  }

  const handleMaybeAddWatcher = () => {
    const { watcher_ids } = orderStore.selectedOrder
    orderStore.updateSelectedOrder('watcher_ids', _.uniq(watcher_ids.concat([userStore.currentUser?.id])))
  }

  const handleSaveOrder = async (
    state_changes: {
      ordered_at?: string
      cancelled_at?: string
      cancellation_accepted_at?: string
      cancellation_rejected_at?: string
      cancellation_requested_at?: string
      cancellation_requested_reason?: string
    },
    silent_update?: boolean,
  ) => {
    // When user update the delivery drawer we add this updated prop as true, and here we check if we need to save
    const deliveriesToUpdate = orderStore.selectedOrder?.deliveries?.filter((delivery) => delivery?.updated)

    if (deliveriesToUpdate.length) {
      await Promise.all(
        deliveriesToUpdate?.map((delivery) =>
          deliveryStore.updateDelivery({ ...delivery, silent_update: true, generate_purchase_order_pdf: false }),
        ),
      )
    }

    return orderStore
      .updateOrder(
        {
          state_changes,
          purchaser_files_signed_ids: uploaderStore.signedIds('purchaser_files'),
          purchaser_files_delete_ids: uploaderStore.deleteAttachmentIds['purchaser_files'],
          quote_signed_id: _.get(uploaderStore.signedIds('quote'), '[0]'),
          silent_update,
        },
        userStore.canCreateNewMaterial,
      )
      .then(() => orderStore.setOrderDirty())
  }

  const handleSave = async ({
    stateChanges,
    action,
    silentUpdate,
    isBack = true,
  }: {
    stateChanges?: { cancelled_at?: Nullable<string> }
    action?: number
    silentUpdate?: boolean
    isBack?: boolean
  }) => {
    if (stateChanges?.['cancelled_at'] && orderStore.anyDelivered) {
      Modal.error({ title: 'You cannot cancel an order that has already been delivered.' })
      return
    }
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, getRequiredOrderFields())
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = handleValidateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    states.setCountActionClicked(action)
    handleMaybeAddWatcher()

    try {
      await handleSaveOrder(stateChanges, silentUpdate)

      const orderType =
        [OrderStates.QUOTED].includes(orderStore.selectedOrder?.state) ||
        [OrderSubStates.CANCELLED_QUOTE_BY_BUYER].includes(orderStore.selectedOrder.sub_state)
          ? 'RFQ'
          : 'Order'

      notifyOrder({ message: `Updated ${orderType}` })

      if (isBack) {
        handleGoBack()
      }
    } catch (error) {
      handleLogError(error)
    } finally {
      states.setCountActionClicked(-1)
    }
  }

  const handleSendInternalComment = async ({ text, companyUserIds }) => {
    states.setSubmitting(true)
    try {
      await orderStore.sendInternalComment({ text, companyUserIds: [...companyUserIds, userStore.currentUser?.id] })
    } catch (error) {
      message.error('Unable to send message')
    } finally {
      states.setSubmitting(false)
    }
  }

  const handleDirtyOrder = () => {
    orderStore.setOrderDirty()
  }

  const handleUpdateOrderPackageName = (name = '') => orderStore.updateSelectedOrder('order_package_name', name)
  const handleSelectOrder = async (orderId: string) => await orderStore.selectOrder(orderId)
  const handleConfirmOrder = async () => await orderStore.confirmOrder()
  const handleManualExport = async (orders: { id: string }[]) => await orderStore.manual_export(orders)

  const { handlePlaceOrder } = usePlaceOrderAction(states, {
    getRequiredOrderFields,
    handleMaybeAddWatcher,
    handleValidateProject,
    handleSaveOrder,
    handleGoBack,
    handleLogError,
  })

  const { handleCancelOrder } = useCancelOrderAction(states, {
    getRequiredOrderFields,
    handleValidateProject,
    handleSaveOrder,
    handleGoBack,
    handleLogError,
  })

  const { handleRequestCancellation, handleRollbackRequestCancellation } = useRequestCancellationAction(states, {
    handleSaveOrder,
    handleGoBack,
    handleLogError,
  })

  return {
    handleGoBack,
    handleRedirectToDraftPage,
    handleSave,
    handlePlaceOrder,
    handleCancelOrder,
    handleRequestCancellation,
    handleRollbackRequestCancellation,
    handleSendInternalComment,
    handleDirtyOrder,
    handleUpdateOrderPackageName,
    handleSelectOrder,
    handleConfirmOrder,
    handleManualExport,
  }
}
