import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { OrderDeliveryCosts } from 'common/components/@v2/OrderDeliveryCosts'
import { OrderMaterials } from 'common/components/@v2/OrderMaterials/order_materials'
import { Box } from 'common/components/boxes'
import { DataSource } from 'common/components/OrderMaterialsV2'
import { calcOrderTaxSplitBulk, roundValue } from 'common/helpers/order'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { useNewQuote } from 'contractor/pages/NewQuote/context'

import { MaterialsAndDeliveriesProps } from './materials_and_deliveries'

type MaterialsV2Props = {
  deliveryId: string
  index: number
  overBudgetMaterial?: string
  isPoLocked?: boolean
  deliveryName: string
} & Pick<MaterialsAndDeliveriesProps, 'disabled' | 'orderType'>

export const MaterialsV2 = observer<MaterialsV2Props>(
  ({ deliveryId, index, disabled, orderType, overBudgetMaterial, isPoLocked, deliveryName }) => {
    const { orderStore, userStore, companySettingStore, costCodeStore, projectStore, unitsStore } = useStores()

    const { showDefaultVendor, companyVendors, projectId, handleDefaultVendorsPricesApplied, commitment } =
      useNewQuote()

    const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const requiredOrderFields = companySettingStore.otherSettings?.required_order_fields
    const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields
    const showDeleteItem =
      orderStore?.selectedOrder?.requested_by_id == userStore?.currentUser?.id || !userStore?.cannotSendAndUpdateOrders

    const { costCodePhaseListStore } = costCodeStore
    const isDraft =
      orderStore?.selectedOrder?.state === OrderStates.DRAFT || orderStore?.selectedOrder?.state === undefined

    const handleAdd = () => {
      const button = document.getElementsByClassName('aa-DetachedSearchButton')[0] as HTMLElement
      const input = document.getElementsByClassName('aa-Input')[0] as HTMLElement
      button?.click()
      input?.focus()
    }

    const companyVendorId = companyVendors[0]?.id

    const delivery = orderStore.newDeliveries.find((delivery) => delivery.id === deliveryId)

    const currentProjectId = projectId || projectStore?.selectedProject?.id

    const project = projectStore.projects.find((project) => project.id === currentProjectId)

    // Only show tax if it is enabled by the project and it is an ORDER
    const hideTax = !project?.tax_line_items?.enabled || orderType === 'RFQ'

    const dataSource = orderStore
      .getOrderMaterialsByDeliveryId(delivery.id)
      .filter((data) => !!data.company_material?.description)

    return (
      <>
        <Box display={{ _: 'flex', xs: 'none' }} width="100%" flexDirection="column">
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography.Title level={5} style={{ margin: 0 }}>
              Materials
            </Typography.Title>
            <Button icon={<PlusOutlined />} onClick={handleAdd} size="small" />
          </Box>

          <Divider style={{ margin: '12px 0' }} />
        </Box>

        <OrderMaterials
          showDefaultVendor={company_attributes.includes('preferred_vendor_prices')}
          orderType={orderType}
          dataSource={dataSource as unknown as Array<Omit<DataSource, 'internal_id'>>}
          onChange={(newOrderMaterials) => {
            if (newOrderMaterials.some((material) => !!material?.tax_value) && project?.tax_line_items?.enabled) {
              const totalTaxSumFromOrderMaterials = newOrderMaterials.reduce(
                (acc, material) => acc + (Number(material?.tax_value) || 0),
                0,
              )
              delivery.tax_value = roundValue(totalTaxSumFromOrderMaterials)
            }

            orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
            orderStore.setOrderDirty()
          }}
          isSelecting={orderStore.isSplitting}
          hideUnitCost={orderType === 'RFQ'}
          hideVendorResponse
          hideCostCode={!company_attributes.includes('cost_code_id')}
          hideTax={hideTax}
          deliveryId={delivery.id}
          isDraft={isDraft}
          overBudgetMaterial={overBudgetMaterial}
          disabled={orderStore.isSplitting || disabled}
          showDefaultVendorAlert={showDefaultVendor}
          showDeleteItem={showDeleteItem}
          isRequest={userStore.cannotSendAndUpdateOrders}
          showMaterialPriceDetails={userStore.canViewHistoricalPricing}
          companyVendorId={companyVendorId}
          roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
          projectId={currentProjectId}
          commitmentId={commitment?.id}
          unitCostInput={{
            hideDetails: !userStore.canUseHistoricalCost,
            splitting: orderStore.isSplitting,
            onChange: (_value, params) => handleDefaultVendorsPricesApplied(params),
          }}
          costCodeInput={{
            costCodes: toJS(costCodeStore.costCodeListStore.records),
            costCodeSettings,
            projectId: currentProjectId,
          }}
          unitInput={{ units: unitsStore.units, disabled: !userStore.canCreateNewMaterial }}
          units={unitsStore.units}
          phaseCodeInput={{
            phaseCodes: toJS(costCodePhaseListStore.records),
            costCodeSettings,
            projectId: currentProjectId,
          }}
          requiredFields={{
            order: requiredOrderFields,
            quote: requiredQuoteFields,
          }}
          costCodeSettings={costCodeSettings}
          canEditMaterialDatabase={userStore.canEditMaterialDatabase}
          canViewHistoricalPricing={userStore.canViewHistoricalPricing}
          canCreateNewMaterial={userStore.canCreateNewMaterial && !commitment?.id}
          userId={userStore.currentUser?.id}
          orderState={orderStore?.selectedOrder?.state}
          costCodeDisabled={userStore.cannotEditCostCode && userStore.cannotSendAndUpdateOrders}
        />

        <OrderDeliveryCosts
          index={index}
          disabled
          hideAddTotal={userStore.isMaterialRequester}
          deliveryChargesUnits={delivery.delivery_charges_units}
          onChangeChargesUnit={(value) => (delivery.delivery_charges_units = value)}
          deliveryTotalCost={orderStore.deliveryTotalCost({
            deliveryId: delivery.id,
            deliveries: orderStore.newDeliveries,
            precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
          })}
          isPoLocked={isPoLocked || disabled}
          hideTotal={orderType === 'RFQ' || orderStore.isSplitting}
          orderMaterialsTotalCost={orderStore.materialsTotalCost(orderStore.getOrderMaterialsByDeliveryId(delivery.id))}
          onChange={(value) => {
            delivery.discount_value = value.discountValue
            delivery.shipping_value = value.shippingValue
            delivery.other_value = value.otherValue
            delivery.tax_value = value.taxValue

            if (project?.tax_line_items?.enabled) {
              const newOrderMaterials = calcOrderTaxSplitBulk({
                orderMaterials: dataSource,
                taxAmount: value.taxValue,
                taxLineItemsEnabled: true,
                precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
              })
              orderStore.updateOrderMaterialsByDeliveryId(delivery.id, newOrderMaterials)
            }
          }}
          roundingPrecision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
          value={{
            discountValue: delivery?.discount_value,
            shippingValue: delivery?.shipping_value,
            otherValue: delivery?.other_value,
            taxValue: delivery?.tax_value,
          }}
          deliveryName={deliveryName}
        />
      </>
    )
  },
)
