import React, { useState } from 'react'

import { Dropdown, MenuProps, Modal, Input, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceDiscardDrawer } from 'contractor/pages/Invoices/Detail/invoice_discard_drawer'

import { useInvoice } from '../context'
import { InvoiceHistory } from '../invoice_history'
import { SourceFrom } from './source_from'

export const DropdownActions = observer(() => {
  const { invoiceStore, userStore, companySettingStore } = useStores()

  const { setSelectedInvoiceDirty, lockInvoice } = useInvoice()

  const [isHistoryDrawerVisible, toggleHistoryDrawerVisible] = useState(false)
  const [isInvoiceDiscardDrawerVisible, toggleInvoiceDiscardDrawerVisible] = useState(false)
  const [isIssueModalVisible, toggleIssueModalVisible] = useState(false)

  const [issueMessage, setIssueMessage] = useState('')

  const { invoice } = invoiceStore

  const handleSendIssue = async () => {
    try {
      await invoiceStore.reportIssue(invoice.id, { issue_message: issueMessage })
      message.success('Thanks for your issue report!\n Our team will take care of it ASAP.')
      setIssueMessage('')
    } catch {
      message.error('Error while sending the issue, try again later')
    } finally {
      toggleIssueModalVisible(false)
    }
  }

  const getDropdownActions = () => {
    const canViewSourceFrom =
      userStore.canViewAllInvoices ||
      userStore.canViewInvoiceInbox ||
      userStore.canViewAllMyProjectInvoices ||
      userStore.canEditInvoices

    const actions: MenuProps['items'] = [
      {
        label: 'History',
        key: 'history',
        onClick: () => toggleHistoryDrawerVisible(true),
      },
      {
        label: 'Report Issue',
        key: 'report-issue',
        onClick: () => toggleIssueModalVisible(true),
      },
    ]

    actions.push({
      label: (
        <WatchersDrawer
          boxProps={{
            width: 'auto',
            p: 0,
            height: 'auto',
            border: 'none',
            display: 'block',
          }}
          value={companySettingStore.possibleUsers
            .filter((user) => invoice?.watcher_ids?.includes(user.company_user_id))
            .map((user) => makeCompanyUserWatcherOption(user))}
          onChange={(watchers = []) => {
            invoice.watcher_ids = watchers?.map((watcher) => watcher.value)
            setSelectedInvoiceDirty(true)
          }}
          isDisabled={!lockInvoice.canEditInvoice}
          makeOption={makeCompanyUserWatcherOption}
        >
          Watchers
        </WatchersDrawer>
      ),
      key: 'watchers',
    })

    if (canViewSourceFrom) {
      actions.push({
        label: <SourceFrom />,
        key: 'source-from',
      })
    }

    if (userStore.canDeleteInvoices) {
      actions.push({
        label: 'Delete Invoice',
        danger: true,
        onClick: () => toggleInvoiceDiscardDrawerVisible(true),
        key: 'delete',
      })
    }

    return actions
  }

  return (
    <>
      <InvoiceHistory onClose={() => toggleHistoryDrawerVisible(false)} open={isHistoryDrawerVisible} />

      <InvoiceDiscardDrawer
        onClose={() => toggleInvoiceDiscardDrawerVisible(false)}
        open={isInvoiceDiscardDrawerVisible}
      />

      <Modal
        title="Describe the issue here and hit OK"
        open={isIssueModalVisible}
        onCancel={() => toggleIssueModalVisible(false)}
        okButtonProps={{ onClick: handleSendIssue, disabled: !issueMessage.trim().length }}
      >
        <Input.TextArea
          value={issueMessage}
          onChange={(e) => setIssueMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      <Dropdown.Button
        menu={{ items: getDropdownActions() }}
        trigger={['click']}
        buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
        style={{ width: 'auto' }}
      />
    </>
  )
})
